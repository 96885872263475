import { useLosseBlogBink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function Pagination() {
  const { hasPrevPage, hasNextPage, prevPage, nextPage } = useLosseBlogBink()

  return (
    <div className="mt-10 flex gap-3 px-4 py-2 justify-end bg-transparent">
      <button
        type="button"
        className={clsx(
          'w-8 h-8 lg:w-10 lg:h-10 !border-solid flex items-center justify-center bg-transparent !border !border-white button-base-ring disabled:opacity-25',
          hasPrevPage &&
          'hover:bg-white children-path:hover:!fill-lm-machine hover:text-iw-jasmine focus:ring-offset-white focus:ring-ab-tidal'
        )}
        disabled={!hasPrevPage}
        onClick={prevPage}
      >
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.59986 7.71006C2.50613 7.6171 2.43174 7.5065 2.38097 7.38464C2.3302 7.26278 2.30406 7.13207 2.30406 7.00006C2.30406 6.86805 2.3302 6.73734 2.38097 6.61548C2.43174 6.49362 2.50613 6.38302 2.59986 6.29006L7.18986 1.71006C7.28359 1.6171 7.35798 1.5065 7.40875 1.38464C7.45952 1.26278 7.48566 1.13207 7.48566 1.00006C7.48566 0.868048 7.45952 0.737343 7.40875 0.615483C7.35798 0.493624 7.28359 0.383023 7.18986 0.29006C7.0025 0.103809 6.74905 -0.000732422 6.48486 -0.000732422C6.22067 -0.000732422 5.96722 0.103809 5.77986 0.29006L1.18986 4.88006C0.628058 5.44256 0.3125 6.20506 0.3125 7.00006C0.3125 7.79506 0.628058 8.55756 1.18986 9.12006L5.77986 13.7101C5.96612 13.8948 6.21752 13.999 6.47986 14.0001C6.61147 14.0008 6.74193 13.9756 6.86377 13.9258C6.9856 13.8761 7.09642 13.8027 7.18986 13.7101C7.28359 13.6171 7.35798 13.5065 7.40875 13.3846C7.45952 13.2628 7.48566 13.1321 7.48566 13.0001C7.48566 12.868 7.45952 12.7373 7.40875 12.6155C7.35798 12.4936 7.28359 12.383 7.18986 12.2901L2.59986 7.71006Z"
            fill="#FFFFFF"
          />
        </svg>
      </button>
      <button
        type="button"
        className={clsx(
          'w-8 h-8 lg:w-10 lg:h-10 !border-solid !border !border-white flex items-center justify-center bg-transparent button-base-ring disabled:opacity-25',
          hasNextPage &&
          'hover:bg-white children-path:hover:!fill-lm-machine hover:text-iw-jasmine focus:ring-offset-white focus:ring-ab-tidal'
        )}
        disabled={!hasNextPage}
        onClick={nextPage}
      >
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.40029 4.88006L1.81029 0.29006C1.62293 0.103809 1.36948 -0.000732422 1.10529 -0.000732422C0.841105 -0.000732422 0.587653 0.103809 0.400291 0.29006C0.306562 0.383023 0.232168 0.493624 0.181399 0.615483C0.130631 0.737343 0.104492 0.868048 0.104492 1.00006C0.104492 1.13207 0.130631 1.26278 0.181399 1.38464C0.232168 1.5065 0.306562 1.6171 0.400291 1.71006L5.00029 6.29006C5.09402 6.38302 5.16841 6.49362 5.21918 6.61548C5.26995 6.73734 5.29609 6.86805 5.29609 7.00006C5.29609 7.13207 5.26995 7.26278 5.21918 7.38464C5.16841 7.5065 5.09402 7.6171 5.00029 7.71006L0.400291 12.2901C0.211987 12.477 0.105672 12.7312 0.104734 12.9965C0.103797 13.2619 0.208314 13.5168 0.395291 13.7051C0.582269 13.8934 0.836391 13.9997 1.10176 14.0006C1.36712 14.0016 1.62199 13.897 1.81029 13.7101L6.40029 9.12006C6.96209 8.55756 7.27765 7.79506 7.27765 7.00006C7.27765 6.20506 6.96209 5.44256 6.40029 4.88006Z"
            fill="#FFFFFF"
          />
        </svg>
      </button>
    </div>
  )
}
