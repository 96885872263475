import { FormDuufGroup, FormDuufSubmit, useFormDuuf } from '@ubo/form-duuf'
import { useLoaderData, useLocation, useNavigate } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type {
  Assignment,
  Page_Flexcontent_Flex_Assignments,
  Page_Flexcontent_Flex_Text,
  Page_Flexcontent_Flex_Vacancies,
  Vacancy
} from '~/graphql/types'

export default function Sollicitation({
  fields
}: {
  fields: Page_Flexcontent_Flex_Vacancies & Page_Flexcontent_Flex_Text & Page_Flexcontent_Flex_Assignments
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const { page } = useLoaderData<{ page: Vacancy | Assignment }>()


  useEffect(() => {
    if (location.hash !== '#solliciteer') {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [location])

  if (!show) return null


  return (
    <section
      onClick={() => {
        navigate('#', {
          state: {
            scroll: false
          }
        })
        setShow(false)
      }}
      className="bg-black/50 cursor-pointer fixed h-full w-full top-0 left-0 z-50 flex justify-center items-center"
      id="Sollicitation"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white relative flex flex-col gap-3 max-w-[1024px] lg:min-w-[500px] p-6 lg:p-10 cursor-auto shadow"
      >
        <div className="absolute top-6 right-6">
          <svg
            onClick={() => {
              navigate('#', {
                state: {
                  scroll: false
                }
              })
              setShow(false)
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x cursor-pointer"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div>
          <div className="flex flex-col gap-2 pb-5 md:pb-10">
            <Content className="text-lm-shoe children-h2:text-3xl md:children-h2:text-5xl ">{fields.formgroup?.description}</Content>
            <p className="text-sm font-secondary font-light">{page.title}</p>
          </div>
          <Form
            defaultFields={{
              input_9: page.title || ''
            }}
            data={fields.formgroup?.form}
            generate={false}
            privacyUrl="/privacyverklaring/"
            privacyUrlPlacement="Privacy Voorwaarden"
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => <Content className="text-black pt-5">{content}</Content>}
          >
            <FormContent data={fields.formgroup?.form} />
          </Form>
        </div>
      </div>
    </section>
  )
}

function FormContent({ data }: { data: Page_Flexcontent_Flex_Vacancies }) {
  const duuf = useFormDuuf()
  let buttonText = 'Solliciteer nu';

  if (duuf?.formik?.values?.input_10_1 === 'Ik heb nog geen CV, maak CV aan') {
    buttonText = 'Solliciteer en maak CV';
  }

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-11">
        <FormDuufGroup showIds={[1, 3]} />
      </div>
      <FormDuufGroup showIds={[4, 5, 6, 12]} />
      <FormDuufGroup showIds={[10]} />
      {(duuf?.formik?.values?.input_10 === '' || duuf?.formik?.values?.input_10_1 !== 'Ik heb nog geen CV, maak CV aan') && <FormDuufGroup showIds={[7]} />}
      <FormDuufGroup showIds={[8]} />
      <div className="flex justify-end">
        <SubmitButton text={buttonText} />
      </div>
    </div>
  )
}

const SubmitButton = ({ text }: { text: string }): React.ReactElement => {
  const context = useFormDuuf()
  const isValid: boolean =
    context.captchaFilled &&
    context.formik.isSubmitting === false &&
    Object.keys(context.formik.touched).length > 0 &&
    Object.keys(context.formik.errors).length === 0

  return (
    <div className="form-duuf-group form-duuf-group-submit">
      <span className={`form-duuf-submit${!isValid ? ' form-duuf-submit-disabled' : ''}`}>
        <button type="submit" disabled={!isValid}>
          {text}
        </button>
      </span>
    </div>
  )
}
