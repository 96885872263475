import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerBig({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerBig" className="relative">
      <LossePlaatjie
        maxWidth={2000}
        className="absolute opacity-20 mix-blend-luminosity h-full object-cover z-0 w-full top-0 left-0"
        src={fields.image}
        alt="banner-image-big"
      />

      <div className="section container relative z-20">
        <div className="max-w-screen-lg mx-auto py-10 sm:py-20 lg:py-40">
          <Content className="text-white children-h1:opacity-40 children-h1:text-4xl sm:children-h1:text-6xl lg:children-h1:text-8xl children-h1:leading-[1.2] children-h1:font-medium">
            {fields.description}
          </Content>
        </div>
      </div>
    </section>
  )
}
