import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Test from '~/components/elements/Modals/Test'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function ZZPTest({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="ZZPTest" className="bg-lm-machine section">
      <Test fields={fields} />
      <div className="container text-white">
        <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-20">
          <div className="col-span-1">
            <Content className="children-p:text-[100px] children-p:leading-[90px] lg:children-p:text-[150px] children-p:font-secondary lg:children-p:leading-[130px] children-p:font-semibold children-p:opacity-[0.15]">
              {fields.backgroundtext}
            </Content>
          </div>
          <div className="col-span-1">
            <div className="bg-white p-8 lg:p-12 flex flex-col gap-9">
              <Content className="text-black">{fields.description}</Content>
              <div className="flex justify-center">
                <Button to="#test" className="button-cadmium">
                  {fields.button}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
