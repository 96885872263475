import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import FormController from '~/components/elements/Form/FormController'
import { type CustomFormField } from '~/components/elements/Form/FormController'
import RestartForm from '~/components/elements/Form/RestartForm'
import { useLocation, useNavigate } from '@ubo/losse-sjedel'
import { useFormDuuf } from '@ubo/form-duuf'
import CloseForm from '../Form/CloseForm'

export default function Test({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [step, setStep] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const [amountOfSteps, setAmountOfSteps] = useState(0)
  const [layout, setLayout] = useState<{
    [key: number]: CustomFormField[]
  }>({})
  const [show, setShow] = useState(false)
  const formduuf = useFormDuuf();

  useEffect(() => {
    if (location.hash !== '#test') {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [location])

  useEffect(() => {
    let cursor: number = -1
    const layoutTemplate: {
      [key: number]: CustomFormField[]
    } = {}

    fields.form?.formFields?.nodes.forEach((field) => {
      if (field.type === 'SECTION') {
        cursor += 1
        layoutTemplate[cursor] = []
      }
      layoutTemplate[cursor].push(field)
    })

    setAmountOfSteps(cursor + 1)
    setLayout(layoutTemplate)
  }, [fields.form?.formFields?.nodes])

  if (!show) return null

  return (
    <section
      onClick={() => {
        navigate('#', {
          state: {
            scroll: false
          }
        })
        setShow(false)
      }}
      id="Form"
      className="min-h-screen cursor-pointer w-full z-50 flex justify-center items-center bg-black/40 fixed top-0 left-0"
    >
      <div onClick={(e) => e.stopPropagation()} className="bg-white cursor-auto md:min-w-[800px] p-6 md:p-12 shadow-lm-basic">
        <div className="relative z-10">
          <Form
            className="full-form luxe-form h-full"
            data={fields.form}
            generate={false}
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => <Content className="text-lm-shoe font-bold text-center text-xl">{content}</Content>}
          >
            <>
              <div className="flex flex-col gap-6 lg:gap-14">
                <div className="justify-between flex">
                  <Button
                    className={clsx(step === 0 && 'disabled', 'button-cadmium')}
                    onClick={() => {
                      const element = document.querySelector('#test')

                      element?.scrollIntoView({ behavior: 'smooth' })
                      setStep(step - 1)
                    }}
                  >
                    Terug
                  </Button>
                  <div className="flex gap-3">
                    <RestartForm setStep={setStep} />
                    <CloseForm setStep={setStep} setShow={setShow} />
                  </div>
                </div>
              </div>

              <div className="max-w-screen-lg mx-auto w-full pt-5 sm:pt-10 lg:pt-10">
                <FormController layout={layout} step={step} setStep={setStep} amountOfSteps={amountOfSteps} />
              </div>
            </>
          </Form>
        </div>
      </div>
    </section>
  )
}
