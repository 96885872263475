import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import { useLocation, useNavigate } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function Acquainted({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (location.hash !== '#kennismakingsgesprek') {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [location])

  if (!show) return null

  return (
    <section
      onClick={() => {
        navigate('#', {
          state: {
            scroll: false
          }
        })
        setShow(false)
      }}
      className="bg-black/50 cursor-pointer fixed h-full w-full top-0 left-0 z-50 flex justify-center items-center"
      id="Acquainted"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white relative flex flex-col gap-3 max-w-[1024px]  min-w-[500px] p-10 cursor-auto shadow"
      >
        <div className="absolute top-6 right-6">
          <svg
            onClick={() => {
              navigate('#', {
                state: {
                  scroll: false
                }
              })
              setShow(false)
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x cursor-pointer"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div>
          <Content className="text-black children-h2:text-5xl pb-10">{fields.description}</Content>
          <Form
            data={fields.form}
            generate={false}
            privacyUrl="/privacyverklaring/"
            privacyUrlPlacement="Privacy Voorwaarden"
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => <Content className="text-black pt-5">{content}</Content>}
          >
            <div>
              <div className="grid grid-cols-2 gap-x-11">
                <FormDuufGroup showIds={[4, 9, 1, 6, 10, 3]} />
              </div>
              <div className="flex justify-end">
                <FormDuufSubmit />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  )
}
