import { Link } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function Bookmarks({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="Bookmarks">
      <div className="container lg:pb-0 pb-10">
        <div className="max-w-screen-xl mx-auto w-full grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-32">
          <div className="flex justify-center flex-col gap-10 section-sm">
            <Content className="text-white">{fields.description}</Content>
            <div>
              {fields.links?.map((link, index) => (
                <Link className="button-cadmium-lg" key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex justify-center min-h-[480px]">
            {fields.items?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Link className="group" key={index} to={item?.link?.url || '/'}>
                <div className="relative bg-lm-machine h-[300px] mx-[10px] lg:mx-5 w-[110px]">
                  {
                    {
                      0: (
                        <>
                          <div className="absolute h-0 w-0 bg-transparent -bottom-[180px] border-l-[55px] border-transparent border-r-[55px] border-t-[25px] border-t-lm-machine" />
                          <div className="absolute h-[155px] w-[110px] bg-lm-machine -bottom-[155px]" />
                        </>
                      ),
                      1: (
                        <>
                          <div className="absolute h-0 w-0 rotate-180 bg-transparent -bottom-[145px] lg:-bottom-[135px] border-l-[0] border-l-transparent border-r-lm-machine border-r-[110px] border-t-[55px] border-t-transparent" />
                          <div className="absolute h-[90px] lg:h-[80px] w-[110px] bg-lm-machine -bottom-[90px] lg:-bottom-[80px]" />
                        </>
                      ),
                      2: (
                        <>
                          <div className="absolute h-0 w-0 rotate-180 bg-transparent -bottom-[80px] lg:-bottom-[60px] border-l-[0] border-l-transparent border-r-lm-machine border-r-[110px] border-t-[55px] border-t-transparent" />
                          <div className="absolute h-[25px] lg:h-[5px] w-[110px] bg-lm-machine -bottom-[25px] lg:-bottom-[5px]" />
                        </>
                      )
                    }[index]
                  }
                  <div className="text-right group-hover:text-white smooth absolute -rotate-180 [writing-mode:vertical-lr] [text-orientation:sideways-right] top-[65px] text-white/30 left-[17px] font-secondary text-[26px] leading-[75px] font-bold">
                    {item?.link?.title}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
