import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import Workmethod from './Workmethod'
import Share from './Share'
import BrandSlider from './BrandSlider'
import Packets from './Packets'
import WhiteSpace from './WhiteSpace'

export default function MiscShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    workmethod: Workmethod,
    share: Share,
    brandslider: BrandSlider,
    packets: Packets,
    whitespace: WhiteSpace
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
