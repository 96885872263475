import Test from '~/components/elements/Modals/Test'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function TestModal({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="TestModal">
      <Test fields={fields} />
    </section>
  )
}
