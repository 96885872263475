import { LosseBlogBink } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Content from '~/components/elements/Content'

export default function PostsBlog({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsBlog" className="section">
      <div className="container pt-10 lg:pt-16">
        <div className="max-w-screen-xl mx-auto flex flex-col gap-8 lg:gap-16">
          <div className="flex flex-col lg:items-center lg:flex-row gap-3 lg:gap-8">
            <Content className="children-h2:whitespace-nowrap text-white">{fields.title}</Content>
            <Content className="text-white">{fields.description}</Content>
          </div>
          <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
            <Overview fields={fields} />
          </LosseBlogBink>
        </div>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 sm:gap-10 gap-6 lg:gap-20">
        {fields.posts?.edges?.map((edge) => {
          if (!edge || !edge.node) return null

          const Component = PostTypes[postTypeName] || PostTypes.Post

          return (
            <div key={edge.node.uri} className="col-span-1">
              <Component data={edge.node} />
            </div>
          )
        })}
      </div>
    </>
  )
}
