import { useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import ExtendedPreview from '~/components/elements/Members/ExtendedPreview'
import type { Member, Page_Flexcontent_Flex_Members } from '~/graphql/types'

export default function Members({ fields }: { fields: Page_Flexcontent_Flex_Members }) {
  const [members, setMembers] = useState<{
    [key: string]: Member[]
  }>({})
  const [openIndex, setOpenIndex] = useState<string>('0')

  const filteredMembers = fields.members?.edges?.filter(({ node }) => node.databaseId !== 1502)

  useEffect(() => {
    const staticMembers = JSON.parse(JSON.stringify(filteredMembers))
    const placeholder: {
      [key: string]: Member[]
    } = {
      default: []
    }

    staticMembers.forEach(({ node: member }: { node: Member }) => {
      placeholder.default.push(member)
    })

    setMembers(placeholder)
  }, [fields])



  return (
    <section id="Members" className="section bg-lm-shoe">
      <div className="container">
        <div className="max-w-screen-xl mx-auto flex flex-col gap-8 lg:gap-20">
          <div className="flex flex-col lg:items-center lg:flex-row gap-3 lg:gap-8">
            <Content className="children-h2:whitespace-nowrap text-white">{fields.title}</Content>
            <Content className="text-white">{fields.description}</Content>
          </div>
          {Object.keys(members).map((department, index) => (
            <div className="flex flex-col gap-7 lg:gap-14" key={`department-${index}`}>
              <div className="max-w-[1380px] mx-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4 lg:gap-20">
                {members[department].map((member, subIndex) => (
                  <ExtendedPreview
                    setOpen={setOpenIndex}
                    index={`${index}-${subIndex}`}
                    open={openIndex}
                    key={`member-${index}-${subIndex}`}
                    fields={member}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
