import EndlessContainer from '~/components/elements/EndlessContainer'
import type { Page_Flexcontent_Flex_Posts, Post } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import { LosseLink } from '@ubo/losse-sjedel'

export default function PostsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section id="Highlighted">
      <EndlessContainer>
        <Slider
          autoplay={{
            delay: 5000
          }}
          breakpoints={{
            0: {
              slidesPerView: 2.25,
              spaceBetween: 30
            }
          }}
        >
          {fields?.posts?.edges?.map((item) => {
            const post = item?.node as Post
            return (
              <Slide key={post?.databaseId}>
                <LosseLink to={post?.uri || ''} className="hover:underline">
                  {post?.title}
                </LosseLink>
              </Slide>
            )
          })}
        </Slider>
      </EndlessContainer>
    </section>
  )
}
