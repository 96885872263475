import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import VacanciesOverview from './VacanciesOverview'
import ChosenVacancies from './ChosenVacancies'

export default function VacanciesShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: VacanciesOverview,
    chosen: ChosenVacancies
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
