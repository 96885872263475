import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FormContact from './FormContact'
import ZZPTest from './ZZPTest'
import Judge from './Judge'
import Acquainted from './Acquainted'
import TestModal from './TestModal'
import Open from './Open'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: FormContact,
    zzptest: ZZPTest,
    testmodal: TestModal,
    judge: Judge,
    acquainted: Acquainted,
    open: Open
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
