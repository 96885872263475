import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import PostsHighlighted from './PostsHighlighted'
import PostsBlog from './PostsBlog'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    highlighted: PostsHighlighted,
    blog: PostsBlog
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
