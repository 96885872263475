import { type LoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className={clsx(isOpen ? `z-100` : `z-30`, `absolute bg-lm-shoe left-0 top-0 right-0 h-fit transition-all`)}>
      <div className="lg:flex h-full items-center justify-between">
        <HeaderDesktop />
        <HeaderMobile isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </nav>
  )
}
