import { useFormDuuf } from '@ubo/form-duuf'
import type { Dispatch, SetStateAction } from 'react'
import Button from '../Button'

export default function RestartForm({ setStep }: { setStep: Dispatch<SetStateAction<number>> }) {
  const formduuf = useFormDuuf()

  return (
    <Button
      className="button-bare hover:bg-lm-cadmium group w-[44px]"
      onClick={() => {
        formduuf.formik.resetForm()
        setStep(0)
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.9618 12.875C21.769 15.1548 20.8025 17.3002 19.2227 18.9552C17.6429 20.6102 15.5448 21.6754 13.2764 21.9739C11.008 22.2725 8.70569 21.7866 6.75145 20.5967C4.79722 19.4069 3.30851 17.5846 2.53232 15.4324C1.75613 13.2801 1.73912 10.9271 2.48411 8.76383C3.22909 6.60055 4.69129 4.75699 6.62812 3.53901C8.56495 2.32103 10.86 1.80184 13.1325 2.06758C15.4049 2.33332 17.5183 3.36801 19.1218 5H15.9998C15.7346 5 15.4803 5.10536 15.2927 5.29289C15.1052 5.48043 14.9998 5.73478 14.9998 6C14.9998 6.26522 15.1052 6.51957 15.2927 6.70711C15.4803 6.89464 15.7346 7 15.9998 7H20.1428C20.6353 6.99974 21.1074 6.804 21.4556 6.45581C21.8038 6.10761 21.9996 5.63543 21.9998 5.143V1C21.9998 0.734784 21.8945 0.48043 21.7069 0.292893C21.5194 0.105357 21.265 0 20.9998 0V0C20.7346 0 20.4803 0.105357 20.2927 0.292893C20.1052 0.48043 19.9998 0.734784 19.9998 1V3.078C17.9885 1.28073 15.4297 0.214484 12.7373 0.0517113C10.0449 -0.111061 7.37627 0.639157 5.16308 2.18103C2.94989 3.72291 1.32152 5.96628 0.541338 8.54832C-0.238841 11.1304 -0.125213 13.9001 0.863846 16.4095C1.85291 18.919 3.65956 21.0214 5.99162 22.3768C8.32367 23.7322 11.0448 24.2614 13.7148 23.8786C16.3848 23.4958 18.8477 22.2236 20.705 20.2676C22.5624 18.3116 23.7056 15.7863 23.9498 13.1C23.9627 12.9607 23.9465 12.8203 23.9022 12.6877C23.8578 12.555 23.7863 12.4331 23.6923 12.3296C23.5982 12.2261 23.4836 12.1434 23.3558 12.0866C23.2279 12.0299 23.0897 12.0004 22.9498 12C22.7059 11.9971 22.4696 12.085 22.2869 12.2468C22.1043 12.4085 21.9884 12.6325 21.9618 12.875Z"
          fill="#000000"
          className="group-hover:fill-white smooth"
        />
      </svg>
    </Button>
  )
}
