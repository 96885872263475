import { type Dispatch, type SetStateAction, useState } from 'react'
import { Link, LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = useState(-1)

  return (
    <div className="flex flex-col gap-20 w-full">
      <div className="flex flex-col gap-5 container relative w-full">
        <ul className="mb-0 sm:w-[calc(100%_-_90px)] gap-4 xl:gap-8 list-none justify-start flex flex-col">
          <NavButton
            className="lg:hidden block"
            key={'home'}
            setOpen={setOpen}
            open={open}
            l={{ link: { title: 'Home', url: '/' } }}
            index={-1}
          />

          {header?.menuHeader?.map((l, index) => (
            <NavButton key={parseInt(`1${index}`)} setOpen={setOpen} open={open} l={l} index={parseInt(`1${index}`)} />
          ))}
        </ul>
      </div>

      <div className="container h-[50px] flex flex-row gap-2 lg:gap-3 justify-center lg:justify-end items-center">
        <a className="button-white-outline" href={header.menuTopper?.phone?.url || '/'}>
          <svg className="lg:w-[24px] lg:h-[24px] h-[20px] w-[20px]" width="24" height="24" viewBox="0 0 24 24">
            <path
              fill="#ffffff"
              d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2z"
            />
          </svg>
          {header.menuTopper?.phone?.title}
        </a>
        <Link className="button-cadmium" to={header.menuTopper?.button?.url || '/'}>
          {header.menuTopper?.button?.title}
        </Link>
      </div>
    </div>
  )
}

const NavButton = ({
  className,
  setOpen,
  index,
  l,
  open
}: {
  className?: string
  setOpen: Dispatch<SetStateAction<number>>
  index: number
  l: any
  open: number
}) => {
  return (
    <motion.li
      onHoverStart={() => setOpen(index)}
      onHoverEnd={() => setOpen(-1)}
      className={clsx(className && className, 'relative px-2')}
      key={index}
    >
      <div className="flex flex-wrap items-center">
        <LosseLink className="navbutton relative" to={l?.link?.url || '/'}>
          {l?.link?.title}
          <AnimatePresence>
            {open === index && (
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                exit={{ width: 0 }}
                className="bg-lm-cadmium absolute h-[3px]"
              />
            )}
          </AnimatePresence>
        </LosseLink>
        {l?.submenu && (
          <div onClick={open === index ? () => setOpen(-1) : () => setOpen(index)} className="ml-[15px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={
                open === index
                  ? {
                      transform: 'rotate(180deg)'
                    }
                  : {
                      transform: 'rotate(0deg)'
                    }
              }
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="#F68A2E"
              strokeWidth={1}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="transition-all"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
        )}
      </div>
      {l?.submenu && open === index && (
        <AnimatePresence>
          <HeaderSubMenu fields={l.submenu} />
        </AnimatePresence>
      )}
    </motion.li>
  )
}
