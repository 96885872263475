import { FormDuufField, FormDuufSubmit, useFormDuuf } from '@ubo/form-duuf';
import clsx from 'clsx';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { type FormField } from '~/graphql/types';
import Button from '../Button';
import CustomCheckboxField from './CustomCheckboxField';
import CustomTextField from './CustomTextField';
import InfoBox from './InfoBox';

export type CustomFormField = FormField & {
  cssClass?: string;
  errorMessage?: string;
  description?: string;
  conditionalLogic?: {
    actionType: string;
    logicType: string;
    rules: {
      fieldId: number;
      operator: string;
      value: string;
    }[];
  };
  isRequired?: boolean;
};

export default function FormController({
  layout,
  step,
  setStep,
  amountOfSteps







}: {layout: {[key: number]: CustomFormField[];};step: number;setStep: Dispatch<SetStateAction<number>>;amountOfSteps: number;}) {
  const form = useFormDuuf();
  const [hasComplication, setComplication] = useState(false);
  const [customMessage, setCustomMessage] = useState('');

  useEffect(() => {
    let complicationTemp = false;

    Object.keys(form.formik.values)?.forEach((key: string) => {

      const matchingFields = layout[step]?.filter((f) => `input_${f.id.toString()}` === key);
      if (matchingFields.length > 0 && form.formik.values[key] !== '') {
        complicationTemp = true;
      }
      if (matchingFields.length > 0 && form.formik.values[key] === 'Nee') {
        setCustomMessage('Nee');
      }
      if (matchingFields.length > 0 && form.formik.values[key] === 'Ja') {
        setCustomMessage('Ja');
      }
    });


    setComplication(complicationTemp);
  }, [form.formik.values, layout, step]);


  return (
    <>
      <div className="grid grid-cols-12 gap-x-5 gap-y-5 lg:gap-y-10">
        {/* Initialize all the col-span classes so the form can all use them :) */}
        {/* lg:col-span-1 lg:col-span-2 lg:col-span-3 lg:col-span-4 lg:col-span-5 lg:col-span-6 lg:col-span-7 lg:col-span-8 lg:col-span-9 lg:col-span-10 lg:col-span-11 lg:col-span-12 */}
        {layout[step]?.map((field, index) => {
          if (field.type === 'CHECKBOX' && field.description) {
            return <CustomCheckboxField key={index} id={field.id} customMessage={customMessage} />;
          }

          if (field.type === 'PHONE' || field.type === 'TEXT' || field.type === 'EMAIL') {
            return <CustomTextField setStep={setStep} step={step} key={index} id={field.id} />;
          }

          return (
            <div key={index} className={`col-span-12 lg:${field.cssClass}`}>
              <FormDuufField id={field.id} />
              {form.formik.values[`input_${field.id}`] === field.errorMessage && <InfoBox description={field.description || ''} />}
            </div>);

        })}
      </div>

      {customMessage === 'Nee' &&
      <div>Jammer dat je geen rijbewijst hebt. Omdat dit wel noodzakelijk is, kunnen we niets voor je betekenen.</div>}


      {customMessage !== 'Nee' &&
      <div className="flex justify-end pt-5 sm:pt-10 lg:pt-20">
          {step + 1 === amountOfSteps ?
        <div className="flex flex-col items-end gap-10">
              {Object.keys(form.formik.errors).length > 0 ||
          Object.keys(form.formik.touched).length === 0 &&
          <div className="bg-red-500 px-6 py-3 text-white">
                    Niet alle velden van het formulier zijn ingevuld als het formulier niet verstuurd kan worden
                  </div>}

              <FormDuufSubmit />
            </div> :

        <>
              <Button
            onClick={() => {
              const element = document.querySelector('#SamenwonenOnline');

              element?.scrollIntoView({ behavior: 'smooth' });
              setStep(step + 1);
            }}
            disabled={!hasComplication}
            className={clsx(!hasComplication && 'disabled', 'button-cadmium')}>

                VOLGENDE
              </Button>
            </>}

        </div>}

    </>);

}