import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import AssignmentsOverview from './AssignmentsOverview'
import ChosenAssignments from './ChosenAssignments'

export default function AssignmentsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: AssignmentsOverview,
    chosen: ChosenAssignments
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
