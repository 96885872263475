import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextWithImageAlt({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextWithImageAlt" className="bg-lm-machine section">
      <div className="container">
        <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20">
          <div className="flex items-center justify-center">
            <LossePlaatjie maxWidth={200} src={fields.image} alt="text-with-image" />
          </div>
          <div className="col-span-1">
            <Content className="text-white">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
