import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TextDefault from './TextDefault'
import Fields from './Fields'
import Bookmarks from './Bookmarks'
import BookmarksHorizontal from './BookmarksHorizontal'
import CTA from './CTA'
import TextWithImage from './TextWithImage'
import Quote from './Quote'
import TextWithImageAlt from './TextWithImageAlt'
import FadedImage from './FadedImage'
import BlockWithImage from './BlockWithImage'
import TextWithIcon from './TextWithIcon'
import VacancyContent from './VacancyContent'
import BlogContent from './BlogContent'

export default function TextShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: TextDefault,
    fields: Fields,
    bookmarks: Bookmarks,
    horizontal: BookmarksHorizontal,
    cta: CTA,
    textwithicon: TextWithIcon,
    textwithimage: TextWithImage,
    textwithimagealt: TextWithImageAlt,
    quote: Quote,
    fadedimage: FadedImage,
    blockwithimage: BlockWithImage,
    vacancycontent: VacancyContent,
    blogcontent: BlogContent
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
