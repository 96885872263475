import { LosseBlogBink, LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Fragment, useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import OpenSollicitation from '~/components/elements/Modals/OpenSollicitation'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies, Vacancy } from '~/graphql/types'

export default function VacanciesOverview({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  return (
    <section id="VacanciesOverview">
      <LosseBlogBink >
        <View fields={fields} />
      </LosseBlogBink>
      <OpenSollicitation fields={fields} />
    </section>
  )
}

function View({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  const news = fields.posts?.edges as { node: Vacancy }[]
  const [searchParams, setSearchParams] = useSearchParams()
  const inputRef = useRef(null)


  return (
    <>
      <div className="bg-lm-shoe">
        <div className="container py-6 lg:py-10">
          <div className="max-w-screen-xl mx-auto flex flex-col gap-5">
            <h2 className="font-secondary text-3xl text-lm-cadmium font-semibold text-center">{fields.search?.title}</h2>
            <div className="flex justify-center">
              <div className="flex justify-center relative flex-row items-center">
                <svg
                  className="absolute top-0 bottom-0 my-auto left-4"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.284089">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.8818 15.468L23.7067 22.293C24.0978 22.684 24.0977 23.316 23.7068 23.707C23.5118 23.902 23.2558 24 22.9998 24C22.7437 24 22.4878 23.902 22.2928 23.707L15.4678 16.882C13.8349 18.204 11.7599 19 9.49991 19C4.26197 19 0 14.738 0 9.49997C0 4.26197 4.26192 0 9.49986 0C14.7378 0 18.9998 4.26202 18.9998 9.50002C18.9998 11.76 18.2038 13.835 16.8818 15.468ZM1.99999 9.49997C1.99999 13.636 5.36394 17 9.49991 17C13.6358 17 16.9998 13.636 16.9998 9.49997C16.9998 5.36395 13.6359 1.99997 9.49991 1.99997C5.36394 1.99997 1.99999 5.36395 1.99999 9.49997Z"
                      fill="black"
                    />
                  </g>
                </svg>

                <form ref={inputRef}>
                  <input
                    name="_q"
                    onKeyDown={(e) => {
                      e.stopPropagation()

                      if (e.key === 'Enter') {
                        e.preventDefault()
                        searchParams.set('_q', e.target.value)
                        setSearchParams(searchParams, {
                          state: {
                            scroll: false
                          }
                        })
                      }
                    }}
                    className="w-full lg:w-[380px] h-[46px] pl-12 pr-6"
                    placeholder={fields.search?.placeholder || ''}
                    defaultValue={searchParams.get('_q') || ''}
                  />
                </form>
                <div
                  onClick={() => {
                    const form = inputRef.current as unknown as HTMLFormElement

                    if (!form) return null

                    searchParams.set('_q', form['_q'].value)
                    setSearchParams(searchParams, {
                      state: {
                        scroll: false
                      }
                    })
                  }}
                  className="button-cadmium cursor-pointer h-full px-5 lg:w-[160px]"
                >
                  {fields.search?.button}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-lm-machine">
        <div className="container flex flex-col gap-8 lg:gap-20 py-6 lg:pt-10 lg:pb-20">
          <div className="max-w-screen-xl mx-auto w-full flex items-center flex-col xl:flex-row justify-between gap-8">
            <Content className="children-strong:children-h2:font-semibold children-h2:font-light children-h2:text-2xl lg:children-h2:text-3xl text-white">
              {fields.description}
            </Content>
            <div className="flex lg:flex-nowrap flex-wrap flex-row justify-center lg:justify-start gap-4 lg:gap-5">
              {fields.filters
                ?.filter((e) => e?.parentDatabaseId === 86)
                .sort((a, b) => (a?.recap?.order || 0) - (b?.recap?.order || 0))
                .map((filter, index) => {
                  const activeFilters = searchParams.get('_f')?.split(',') || []
                  const otherFilterIds = fields.filters?.filter((e) => e?.parentDatabaseId !== filter?.databaseId).map((e) => e?.databaseId)

                  const defaultValue = activeFilters.filter((af: string) => {
                    if (!otherFilterIds?.includes(parseInt(af))) return true
                    return false
                  })[0]

                  return (
                    <div
                      key={index}
                      defaultValue={defaultValue}
                      className={clsx(
                        activeFilters.includes(filter?.databaseId.toString() || '0') && 'border-lm-cadmium bg-lm-cadmium',
                        'w-[120px] cursor-pointer h-[120px] p-4 text-center text-white smooth hover:border-lm-cadmium hover:bg-lm-cadmium uppercase font-secondary text-base !leading-[1] border flex flex-col justify-between items-center border-white'
                      )}
                      onClick={() => {
                        if (activeFilters.includes(filter?.databaseId.toString() || '0')) {
                          searchParams.delete('_f')
                        } else {
                          searchParams.set('_f', `${filter?.databaseId}`)
                        }

                        setSearchParams(searchParams, {
                          state: {
                            scroll: false
                          }
                        })
                      }}
                    >
                      <LossePlaatjie maxWidth={1000} className="w-11" src={filter?.recap?.icon} alt="icon" />
                      {filter?.name}
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="max-w-screen-xl w-full mx-auto grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-20">
            {news.map((newsItem, index) => {
              const vacancy = newsItem.node as Vacancy

              if (index % 4 === 0) {
                return (
                  <Fragment key={index}>
                    <section id="SmallCTA" className={clsx('bg-lm-machine', 'p-8 lg:py-16 col-span-1 lg:col-span-2')}>
                      <div className="bg-lm-cadmium relative py-5">
                        <div className="w-[4000px] -left-[1000px] bg-lm-cadmium top-0 absolute h-full z-10" />
                        <div className="container relative z-20">
                          <div className="max-w-screen-lg mx-auto gap-12 lg:gap-32 flex flex-col lg:flex-row justify-between">
                            <div>
                              <Content className="text-white children-p:text-xl children-p:font-secondary children-p:font-semibold">
                                {fields.opensollicitation?.description}
                              </Content>
                            </div>
                            <div className="flex items-center relative">
                              <div
                                className={clsx(
                                  'bg-lm-machine',
                                  'w-[200%] lg:w-[20px] h-[10px] lg:h-[200%] lg:-rotate-[20deg] absolute -left-[50%] right-0 mx-auto lg:right-[unset] lg:-left-20 -top-[90%] lg:-top-[50%]'
                                )}
                              />
                              <Button className="button-white-outline" to={fields.opensollicitation?.button?.url || '/'}>
                                {fields.opensollicitation?.button?.title}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <Preview fields={vacancy} />
                  </Fragment>
                )
              }
              return <Preview key={index} fields={vacancy} />
            })}
          </div>
          {news.length <= 3 && (
            <section id="SmallCTA" className={clsx('bg-lm-machine', 'pb-6 lg:pb-16 relative')}>
              <div className="bg-lm-cadmium relative py-5">
                <div className="w-[4000px] -left-[1000px] bg-lm-cadmium top-0 absolute h-full z-10" />
                <div className="max-w-screen-lg relative z-20 mx-auto gap-12 lg:gap-32 flex flex-col lg:flex-row justify-between">
                  <div>
                    <Content className="text-white children-p:text-xl children-p:font-secondary children-p:font-semibold">
                      {fields.opensollicitation?.description}
                    </Content>
                  </div>
                  <div className="flex items-center relative">
                    <div
                      className={clsx(
                        'bg-lm-machine',
                        'w-[200%] lg:w-[20px] h-[10px] lg:h-[200%] lg:-rotate-[20deg] absolute -left-[50%] right-0 mx-auto lg:right-[unset] lg:-left-20 -top-[90%] lg:-top-[50%]'
                      )}
                    />
                    <Button className="button-white-outline" to={fields.opensollicitation?.button?.url || '/'}>
                      {fields.opensollicitation?.button?.title}
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* {(hasNextPage || hasPrevPage) && (
            <div className="flex justify-center py-6 lg:py-10">
              <Pagination />
            </div>
          )} */}
        </div>
      </div>
    </>
  )
}
