import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function Workmethod({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="Workmethod">
      <div className="bg-lm-machine">
        <div className="container flex flex-col gap-10 section">
          <Content className="text-white children-p:text-3xl children-p:font-extralight children-p:font-secondary children-p:uppercase">
            {fields.description}
          </Content>
          <div className="grid lg:gap-0 gap-12 grid-cols-1 lg:grid-cols-5 justify-center bg-lm-beluga [box-shadow:0_0_300px_rgba(0,0,0)] p-8 lg:p-16">
            {fields.items?.map((item, index) => (
              <div key={index}>
                <div className="lg:block hidden">
                  <div className="flex justify-center lg:pb-3">
                    <LossePlaatjie maxWidth={800} className="w-[60px] h-[60] lg:w-[100px] lg:h-[80px] object-contain" src={item?.icon} />
                  </div>
                  <div className="relative pt-5 pb-4 flex justify-center">
                    <div className="border-[3px] rounded-full border-solid border-lm-machine bg-lm-beluga w-[50px] h-[50px] flex justify-center items-center relative z-20 font-secondary text-3xl font-bold text-lm-machine">
                      {index + 1}
                    </div>
                    {index !== (fields.items?.length || 0) - 1 && (
                      <div className="bg-lm-cadmium h-[2px] w-[300px] left-[50%] absolute z-10 top-0 bottom-0 my-auto" />
                    )}
                  </div>
                  <Content className="text-center px-3 text-lm-machine leading-[30px]">{item?.description}</Content>
                </div>
                <div className="lg:hidden grid grid-cols-12 relative">
                  {index % 2 === 0 && (
                    <div className="col-span-6 flex justify-center items-center">
                      <div className="border-[3px] border-solid border-lm-machine bg-lm-beluga w-[50px] h-[50px] flex justify-center items-center relative z-20 font-secondary text-3xl font-bold text-lm-machine">
                        {index + 1}
                      </div>
                    </div>
                  )}
                  <div className="col-span-6">
                    <div className="flex justify-center lg:pb-3">
                      <LossePlaatjie maxWidth={800} className="w-[60px] h-[60] lg:w-[100px] lg:h-[80px] object-contain" src={item?.icon} />
                    </div>
                    <Content className="text-center text-lm-machine leading-[30px]">{item?.description}</Content>
                  </div>
                  {index % 2 !== 0 && (
                    <div className="col-span-6 flex justify-center items-center">
                      <div className="border-[3px] border-solid border-lm-machine bg-lm-beluga w-[50px] h-[50px] flex justify-center items-center relative z-20 font-secondary text-3xl font-bold text-lm-machine">
                        {index + 1}
                      </div>
                    </div>
                  )}
                  {index !== (fields.items?.length || 0) - 1 && (
                    <div className="bg-lm-cadmium h-[2px] w-full lg:w-[200px] absolute z-10 top-[calc(100%_+_24px)] lg:top-[72px]" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
