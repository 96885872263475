import type { Assignment, Vacancy } from '~/graphql/types'
import Button from '../Button'
import Content from '../Content'

export default function Preview({ fields }: { fields: Vacancy | Assignment }) {
  return (
    <section id="Preview" className="bg-white relative flex flex-col gap-5 p-6 lg:p-12">
      <div className="absolute right-6 lg:right-12 top-0">
        <Tags className="" />
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 87) && <GasTag className="-mt-[17px]" />}
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 88) && <WaterTag className="-mt-[17px]" />}
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 89) && <ElectraTag className="-mt-[17px]" />}
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 90) && <TelecomTag className="-mt-[17px]" />}
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 91) && <InternTag className="-mt-[17px]" />}
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 92) && <BouwTag className="-mt-[17px]" />}
        {fields.categories?.edges.some((edge) => edge.node.databaseId === 94) && <LogisticsTag className="-mt-[17px]" />}
      </div>
      <div className="flex flex-col gap-10">
        <div className="grid grid-cols-2">
          <h3 className="text-xl md:text-2xl lg:text-3xl min-h-[90px] font-secondary font-semibold">{fields.title}</h3>
        </div>
        <Content className="text-lm-shoe children-p:!leading-[1.4]">{fields.recap?.description}</Content>
      </div>
      <div className="flex justify-end">
        <Button className="button-cadmium-lg" to={fields.uri || '/'}>
          {fields.contentType?.node.name === 'vacancy' ? 'BEKIJK VACATURE' : 'BEKIJK OPDRACHT'}
        </Button>
      </div>
    </section>
  )
}

export function Tags({ big = false, className = '' }: { big?: boolean; className?: string }) {
  return (
    <svg
      className={className}
      width={big ? '120' : '98'}
      height={big ? '77' : '62'}
      viewBox="0 0 98 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.564392 0L0.564392 42.1529L48.3486 61.4604L96.4105 42.1529V0H0.564392Z"
        fill="#2B2B2B"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1689 22.9413V13.1885H11.2219V22.0547H15.4609V22.9413H10.1689Z" fill="white" />
      <mask id="mask0_48_1604" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="98" height="62">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.0263672 0L97.1791 0V62H0.0263672L0.0263672 0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_48_1604)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7802 22.4371H22.3047V14.3633H17.7802V22.4371ZM23.377 21.692C23.377 22.6027 22.937 23.3342 21.6169 23.3342H18.4674C17.1473 23.3342 16.707 22.6027 16.707 21.692V15.1085C16.707 14.1978 17.1473 13.4663 18.4674 13.4663H21.6169C22.937 13.4663 23.377 14.1978 23.377 15.1085V21.692Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.5254 17.1235C26.5254 17.6204 26.7043 17.7445 27.1855 17.7722L29.5648 17.8827C30.885 17.9377 31.3252 18.6278 31.3252 19.5389V21.6917C31.3252 22.6027 30.885 23.3342 29.5648 23.3342H26.9381C25.618 23.3342 25.1777 22.6027 25.1777 21.6917V20.6982L26.1956 20.4773V21.9817C26.1956 22.2299 26.4017 22.4372 26.6491 22.4372H29.7986C30.046 22.4372 30.2525 22.2299 30.2525 21.9817V19.4835C30.2525 19.0143 30.1012 18.8901 29.5924 18.8624L27.2131 18.752C25.893 18.6969 25.453 18.0208 25.453 17.1094V15.1085C25.453 14.1975 25.893 13.4663 27.2131 13.4663H29.4823C30.8025 13.4663 31.2428 14.1975 31.2428 15.1085V16.0192L30.2249 16.2401V14.8185C30.2249 14.5702 30.0187 14.3633 29.771 14.3633H26.9793C26.7319 14.3633 26.5254 14.5702 26.5254 14.8185V17.1235Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.3279 13.4663H39.1492V14.3633H36.2751V23.3342H35.2024V14.3633H32.3279V13.4663Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.1331 13.4663L44.8562 18.6142L47.593 13.4663H49.4083V23.3342H47.4417V17.6617H47.4277L45.0351 22.0371H44.5536L42.2292 17.6617H42.2155V23.3342H40.2353V13.4663H42.1331Z"
          fill="#F7951F"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M51.3046 23.3342H53.3677V13.4663H51.3046V23.3342Z" fill="#F7951F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.1075 13.4663L60.7108 19.2352H60.7245V13.4663H62.6911V23.3342H61.0547L57.2588 17.2479H57.2452V23.3342H55.2646V13.4663H57.1075Z"
          fill="#F7951F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.6232 13.4663V21.4711H69.745V13.4663H71.7941V21.692C71.7941 22.6027 71.3678 23.3342 70.0476 23.3342H66.3206C65.0004 23.3342 64.5605 22.6027 64.5605 21.692V13.4663H66.6232Z"
          fill="#F7951F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.8664 13.4663H79.7429V15.288H77.336V23.3342H75.2729V15.288H72.8664V13.4663Z"
          fill="#F7951F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.842 23.3342V13.4663H86.962V15.288H82.905V17.4275H85.4353V19.2906H82.905V21.5125H87.1681V23.3342H80.842Z"
          fill="#F7951F"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0372 30.8019H87.1682V30.46H10.0372V30.8019Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5179 62L48.2797 61.896L0.0263672 42.4636V0H1.04869V41.7697L48.5438 60.8971L96.1568 41.7694V0H97.1792V42.464L96.8582 42.5929L48.7926 61.9019L48.5179 62Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export function ElectraTag({ big = false, className = '' }: { big?: boolean; className?: string }) {
  return (
    <svg
      className={className}
      width={big ? '119' : '97'}
      height={big ? '53' : '42'}
      viewBox="0 0 97 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0L0 22.4347L48.3572 42L97 22.4347L96.9487 0L48.3572 19.6069L0 0Z" fill="#979797" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0779 26.9786L68.6629 23.2925L68.6837 23.2845L73.1509 25.7989L74.2514 25.3765L69.4327 22.7222L70.9367 18.1067L70.0231 18.4575L68.5837 23.0242L68.563 23.0321L67.22 19.5335L66.4102 19.8443L69.2681 27.2894L70.0779 26.9786ZM62.8212 29.7643L62.5614 29.0875L59.3014 30.3388L56.7033 23.5706L55.8936 23.8814L58.7515 31.3265L62.8212 29.7643ZM57.703 31.7292L57.4431 31.0524L53.8718 32.4233L52.8245 29.6952L54.7348 28.9619L54.463 28.2538L52.5527 28.9871L51.5335 26.3319L54.9491 25.0207L54.6893 24.3439L50.4639 25.9659L53.3218 33.411L57.703 31.7292ZM67.9597 27.0153L68.2196 27.6921L63.8384 29.3739L60.9805 21.9288L65.2059 20.3068L65.4657 20.9836L62.0501 22.2948L63.0693 24.95L64.9796 24.2167L65.2514 24.9248L63.3411 25.6581L64.3884 28.3862L67.9597 27.0153ZM74.6847 17.4448L77.2828 24.213L76.473 24.5239L73.875 17.7556L71.7051 18.5885L71.4453 17.9117L76.5948 15.935L76.8546 16.6118L74.6847 17.4448ZM81.2282 22.6988L80.0011 19.5021L81.0393 19.1035L84.3532 21.4992L85.4122 21.0927L81.9737 18.7449L82.3786 18.5895C83.3753 18.2069 83.4997 17.5379 83.2358 16.8506L82.5523 15.0701C82.2885 14.3828 81.7445 13.9585 80.7478 14.3411L77.5605 15.5645L80.4185 23.0096L81.2282 22.6988ZM79.7411 18.825L82.295 17.8446C82.4819 17.7729 82.5777 17.5569 82.5057 17.3695L81.6583 15.162C81.5864 14.9746 81.3707 14.8782 81.1838 14.9499L78.6299 15.9303L79.7411 18.825ZM86.6059 20.6345L86.4942 18.5747L89.4011 17.4588L90.6964 19.0643L91.61 18.7136L86.1774 12.2568L85.4922 12.5199L85.8169 20.9374L86.6059 20.6345ZM86.4505 17.839L88.9318 16.8865L86.2214 13.5184L86.211 13.5224L86.4505 17.839Z"
        fill="white"
      />
    </svg>
  )
}

export function WaterTag({ big = false, className = '' }: { big?: boolean; className?: string }) {
  return (
    <svg
      className={className}
      width={big ? '119' : '97'}
      height={big ? '53' : '42'}
      viewBox="0 0 97 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.439453L0 22.8741L48.3572 42.4395L97 22.8741L96.9487 0.439453L48.3572 20.0463L0 0.439453Z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.099 29.3462L64.172 23.2386L64.1824 23.2347L67.5906 28.3897L68.172 28.1665L67.0168 20.0679L66.1758 20.3907L67.2018 26.3527L67.1914 26.3567L63.8295 21.2913L63.2273 21.5225L64.1079 27.5403L64.0976 27.5443L60.8706 22.4271L59.957 22.7778L64.5072 29.5733L65.099 29.3462ZM70.8402 27.1423L70.7285 25.0825L73.6354 23.9667L74.9307 25.5721L75.8443 25.2214L70.4116 18.7647L69.7264 19.0277L70.0511 27.4452L70.8402 27.1423ZM70.6839 24.3467L73.1652 23.3942L70.4548 20.0262L70.4444 20.0301L70.6839 24.3467ZM78.3775 24.249L75.7794 17.4808L77.9492 16.6478L77.6894 15.971L72.5399 17.9477L72.7998 18.6245L74.9696 17.7916L77.5677 24.5599L78.3775 24.249ZM85.6345 20.6871L85.8944 21.3639L81.5132 23.0457L78.6553 15.6006L82.8807 13.9786L83.1405 14.6554L79.7249 15.9666L80.7441 18.6218L82.6544 17.8885L82.9262 18.5966L81.0159 19.3299L82.0632 22.058L85.6345 20.6871ZM87.7527 20.6504L86.5256 17.4537L87.5638 17.0551L90.8776 19.4508L91.9366 19.0443L88.4981 16.6965L88.903 16.541C89.8997 16.1584 90.0241 15.4895 89.7603 14.8022L89.0768 13.0216C88.813 12.3344 88.2689 11.9101 87.2722 12.2926L84.085 13.5161L86.9429 20.9612L87.7527 20.6504ZM86.2655 16.7768L88.8194 15.7964C89.0063 15.7247 89.1021 15.5087 89.0301 15.3213L88.1827 13.1138C88.1108 12.9264 87.8951 12.83 87.7082 12.9017L85.1543 13.8821L86.2655 16.7768Z"
        fill="white"
      />
    </svg>
  )
}

export function GasTag({ big = false, className = '' }: { big?: boolean; className?: string }) {
  return (
    <svg
      className={className}
      width={big ? '119' : '97'}
      height={big ? '53' : '42'}
      viewBox="0 0 97 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.439453L0 22.8741L48.3572 42.4395L97 22.8741L96.9487 0.439453L48.3572 20.0463L0 0.439453Z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4971 21.5248L81.6088 23.5846L80.8198 23.8875L80.4951 15.47L81.1803 15.207L86.6129 21.6637L85.6993 22.0144L84.4041 20.409L81.4971 21.5248ZM79.5969 22.9356C79.8607 23.6228 79.7403 24.3022 78.7436 24.6848L76.47 25.5576C75.4733 25.9402 74.9292 25.5158 74.6654 24.8286L72.7588 19.8617C72.495 19.1745 72.6154 18.4951 73.6121 18.1125L75.8857 17.2397C76.8824 16.8571 77.4265 17.2815 77.6903 17.9687L77.9701 18.6976L77.2618 19.1487L76.7062 17.7013L73.3528 18.9886L75.6911 25.08L79.0444 23.7928L78.0492 21.2L76.3465 21.8536L76.0867 21.1768L78.5576 20.2283L79.5969 22.9356ZM83.9347 19.8371L81.4534 20.7896L81.214 16.4731L81.2243 16.4691L83.9347 19.8371ZM91.4116 18.4002C91.6754 19.0874 91.5551 19.7668 90.5584 20.1494L88.5754 20.9106C87.5788 21.2932 87.0347 20.8688 86.7709 20.1816L86.4831 19.4319L87.1874 18.9704L87.6231 20.1054C87.6951 20.2928 87.9107 20.3892 88.0976 20.3175L90.4751 19.4048C90.662 19.3331 90.7577 19.1171 90.6858 18.9297L89.9623 17.045C89.8264 16.691 89.6762 16.6411 89.2841 16.7677L87.4561 17.3739C86.4434 17.7148 85.9153 17.3321 85.6515 16.6449L85.0719 15.135C84.8081 14.4478 84.9285 13.7684 85.9252 13.3858L87.6382 12.7282C88.6349 12.3457 89.1789 12.77 89.4427 13.4572L89.7066 14.1445L89.0022 14.606L88.5905 13.5335C88.5186 13.3461 88.3029 13.2496 88.116 13.3214L86.0085 14.1304C85.8216 14.2021 85.7259 14.4181 85.7978 14.6055L86.4653 16.3444C86.6092 16.7193 86.7801 16.7612 87.1515 16.6425L88.9796 16.0364C89.9922 15.6955 90.5243 16.0886 90.7881 16.7758L91.4116 18.4002Z"
        fill="white"
      />
    </svg>
  )
}

export function TelecomTag({ big = false, className = '' }: { big?: boolean; className?: string }) {
  return (
    <svg
      className={className}
      width={big ? '119' : '97'}
      height={big ? '53' : '42'}
      viewBox="0 0 97 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.439453L0 22.8741L48.3572 42.4395L97 22.8741L96.9487 0.439453L48.3572 20.0463L0 0.439453Z"
        fill="#979797"
      />
      <path
        d="M48.25 27.9872L50.5074 27.0726L53.352 34.0931L54.1945 33.7518L51.3499 26.7312L53.6073 25.8166L53.3228 25.1145L47.9656 27.2852L48.25 27.9872Z"
        fill="white"
      />
      <path
        d="M62.0102 30.585L61.7258 29.8829L58.0103 31.3884L56.8637 28.5585L58.851 27.7533L58.5535 27.0189L56.5661 27.8241L55.4501 25.0699L59.0036 23.6301L58.7192 22.928L54.3232 24.7092L57.4523 32.4318L62.0102 30.585Z"
        fill="white"
      />
      <path
        d="M67.3398 28.4256L67.0553 27.7235L63.6638 29.0977L60.8193 22.0771L59.9768 22.4185L63.1058 30.1411L67.3398 28.4256Z"
        fill="white"
      />
      <path
        d="M72.9587 26.1489L72.6743 25.4468L68.9588 26.9523L67.8122 24.1224L69.7995 23.3172L69.502 22.5827L67.5146 23.388L66.3987 20.6338L69.9521 19.194L69.6677 18.4919L65.2717 20.2731L68.4008 27.9957L72.9587 26.1489Z"
        fill="white"
      />
      <path
        d="M73.4796 24.4415C73.7684 25.1544 74.346 25.5868 75.3828 25.1667L77.327 24.3789C78.3639 23.9588 78.4776 23.2463 78.1887 22.5335L77.7336 21.4102L76.8687 21.572L77.6258 23.4406L74.5583 24.6834L71.9982 18.3649L75.0657 17.1221L75.7746 18.8718L76.5082 18.386L76.1012 17.3815C75.8124 16.6686 75.2348 16.2362 74.198 16.6563L72.2538 17.4441C71.2169 17.8642 71.1032 18.5767 71.3921 19.2895L73.4796 24.4415Z"
        fill="white"
      />
      <path
        d="M82.7798 14.6755C82.491 13.9626 81.9134 13.5302 80.8765 13.9503L78.4031 14.9525C77.3662 15.3726 77.2525 16.0851 77.5414 16.798L79.6289 21.95C79.9177 22.6628 80.4953 23.0952 81.5322 22.6751L84.0055 21.6729C85.0424 21.2528 85.1561 20.5403 84.8673 19.8275L82.7798 14.6755ZM84.2611 20.7521L80.7077 22.1919L78.1475 15.8734L81.701 14.4336L84.2611 20.7521Z"
        fill="white"
      />
      <path
        d="M89.6711 17.7302L89.7143 17.7127L90.2749 12.393L90.2857 12.3887L92.6314 18.1779L93.409 17.8628L90.28 10.1402L89.6643 10.3897L89.0579 16.0925L84.6636 12.4159L83.9939 12.6872L87.1229 20.4098L87.9006 20.0947L85.5549 14.3055L85.5657 14.3011L89.6711 17.7302Z"
        fill="white"
      />
    </svg>
  )
}

export function InternTag({ className = '' }: { className?: string }) {
  return (
    <svg className={className} width="97" height="42" viewBox="0 0 97 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 22.4347L48.3572 42L97 22.4347L96.9487 0L48.3572 19.6069L0 0Z" fill="#979797" />
      <path d="M57.8508 21.9527L61.1027 29.8456L60.2519 30.1961L57 22.3032L57.8508 21.9527Z" fill="white" />
      <path d="M59.6631 21.206L60.3394 20.9274L66.3341 25.2884L66.345 25.2839L63.9391 19.4443L64.7245 19.1207L67.9764 27.0136L67.4528 27.2293L61.1962 22.6663L61.1853 22.6707L63.7004 28.7753L62.915 29.0989L59.6631 21.206Z" fill="white" />
      <path d="M66.0333 19.4208L65.7377 18.7033L71.1481 16.4742L71.4437 17.1917L69.1639 18.131L72.1202 25.3063L71.2693 25.6569L68.3131 18.4815L66.0333 19.4208Z" fill="white" />
      <path d="M80.0136 22.0542L75.4103 23.9508L72.1585 16.0579L76.5981 14.2288L76.8937 14.9463L73.3049 16.4249L74.4647 19.2398L76.4718 18.4129L76.781 19.1636L74.7739 19.9905L75.9655 22.8827L79.7179 21.3367L80.0136 22.0542Z" fill="white" />
      <path d="M77.8682 13.7055L81.217 12.3258C82.2641 11.8943 82.8543 12.3356 83.1544 13.0642L83.9322 14.9518C84.2323 15.6804 84.1198 16.3982 83.0726 16.8297L82.6472 17.0049L86.3669 19.4367L85.2542 19.8951L81.6654 17.4094L80.5746 17.8588L81.9709 21.2478L81.1201 21.5984L77.8682 13.7055ZM79.0146 14.0725L80.279 17.1413L82.9624 16.0357C83.1587 15.9548 83.2541 15.7218 83.1723 15.5231L82.2081 13.1829C82.1262 12.9842 81.8944 12.886 81.698 12.9669L79.0146 14.0725Z" fill="white" />
      <path d="M84.2277 11.0853L84.904 10.8067L90.8987 15.1677L90.9096 15.1633L88.5037 9.32362L89.2891 9.00004L92.5409 16.8929L92.0174 17.1086L85.7608 12.5456L85.7499 12.5501L88.265 18.6546L87.4796 18.9782L84.2277 11.0853Z" fill="white" />
    </svg>

  )
}

export function OpenTag({ className = '' }: { className?: string }) {
  return (
    <svg className={className} width="97" height="42" viewBox="0 0 97 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0L0 22.4347L48.3572 42L97 22.4347L96.9487 0L48.3572 19.6069L0 0Z" fill="#979797" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0779 26.9786L68.6629 23.2925L68.6837 23.2845L73.1509 25.7989L74.2514 25.3765L69.4327 22.7222L70.9367 18.1067L70.0231 18.4575L68.5837 23.0242L68.563 23.0321L67.22 19.5335L66.4102 19.8443L69.2681 27.2894L70.0779 26.9786ZM62.8212 29.7643L62.5614 29.0875L59.3014 30.3388L56.7033 23.5706L55.8936 23.8814L58.7515 31.3265L62.8212 29.7643ZM57.703 31.7292L57.4431 31.0524L53.8718 32.4233L52.8245 29.6952L54.7348 28.9619L54.463 28.2538L52.5527 28.9871L51.5335 26.3319L54.9491 25.0207L54.6893 24.3439L50.4639 25.9659L53.3218 33.411L57.703 31.7292ZM67.9597 27.0153L68.2196 27.6921L63.8384 29.3739L60.9805 21.9288L65.2059 20.3068L65.4657 20.9836L62.0501 22.2948L63.0693 24.95L64.9796 24.2167L65.2514 24.9248L63.3411 25.6581L64.3884 28.3862L67.9597 27.0153ZM74.6847 17.4448L77.2828 24.213L76.473 24.5239L73.875 17.7556L71.7051 18.5885L71.4453 17.9117L76.5948 15.935L76.8546 16.6118L74.6847 17.4448ZM81.2282 22.6988L80.0011 19.5021L81.0393 19.1035L84.3532 21.4992L85.4122 21.0927L81.9737 18.7449L82.3786 18.5895C83.3753 18.2069 83.4997 17.5379 83.2358 16.8506L82.5523 15.0701C82.2885 14.3828 81.7445 13.9585 80.7478 14.3411L77.5605 15.5645L80.4185 23.0096L81.2282 22.6988ZM79.7411 18.825L82.295 17.8446C82.4819 17.7729 82.5777 17.5569 82.5057 17.3695L81.6583 15.162C81.5864 14.9746 81.3707 14.8782 81.1838 14.9499L78.6299 15.9303L79.7411 18.825ZM86.6059 20.6345L86.4942 18.5747L89.4011 17.4588L90.6964 19.0643L91.61 18.7136L86.1774 12.2568L85.4922 12.5199L85.8169 20.9374L86.6059 20.6345ZM86.4505 17.839L88.9318 16.8865L86.2214 13.5184L86.211 13.5224L86.4505 17.839Z"
        fill="white"
      />
    </svg>
  )
}

export function BouwTag({ className = '' }: { className?: string }) {
  return (
    <svg className={className} width="97" height="42" viewBox="0 0 97 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 22.4347L48.3572 42L97 22.4347L96.9487 0L48.3572 19.6069L0 0Z" fill="#979797" />
      <path d="M67.9716 25.7848L67.1646 23.8313C66.9853 23.3972 66.5817 23.2295 66.1476 23.4089L63.7371 24.4046L64.9971 27.4548L67.7503 26.3176C67.9559 26.2326 68.0565 25.9905 67.9716 25.7848ZM62.2364 20.7717L63.4398 23.6849L65.8503 22.6891C66.2844 22.5098 66.452 22.1062 66.2727 21.6721L65.5224 19.8557C65.4374 19.65 65.1953 19.5495 64.9896 19.6344L62.2364 20.7717ZM68.1883 23.6224L68.8207 25.1532C69.1321 25.9072 69.0167 26.6637 67.9199 27.1168L64.4127 28.5655L61.0386 20.3972L64.4887 18.9721C65.5854 18.519 66.2011 18.9735 66.5125 19.7275L67.0364 20.9956C67.2487 21.5097 67.2577 22.3084 66.563 22.7959L66.5725 22.8188C67.4288 22.6255 67.9571 23.0626 68.1883 23.6224Z" fill="white" />
      <path d="M73.7888 16.7218L76.0398 22.1712C76.3512 22.9252 76.2358 23.6817 75.139 24.1347L72.5229 25.2154C71.4262 25.6684 70.8105 25.2139 70.499 24.4599L68.248 19.0106C67.9366 18.2566 68.052 17.5001 69.1488 17.0471L71.7649 15.9664C72.8616 15.5134 73.4773 15.9679 73.7888 16.7218ZM75.4035 23.1562L72.6429 16.473L68.8843 18.0256L71.645 24.7088L75.4035 23.1562Z" fill="white" />
      <path d="M75.0394 14.6138L75.9304 14.2457L78.9978 21.6714L82.3566 20.284L79.2892 12.8583L80.1802 12.4902L82.9928 19.299C83.3043 20.053 83.2002 20.8048 82.1035 21.2578L79.8758 22.1781C78.7791 22.6311 78.1634 22.1766 77.8519 21.4226L75.0394 14.6138Z" fill="white" />
      <path d="M86.6995 19.3593L81.4632 11.9603L82.4686 11.545L86.1901 17.1224L86.2015 17.1177L85.0618 10.4737L85.7245 10.2L89.5945 15.7161L89.6059 15.7114L88.3063 9.13352L89.2317 8.75128L90.7322 17.6935L90.0925 17.9578L86.1686 12.3436L86.1571 12.3483L87.3507 19.0903L86.6995 19.3593Z" fill="white" />
    </svg>
  )
}

export function LogisticsTag({ className = '' }: { className?: string }) {
  return (
    <svg className={className} width="97" height="42" viewBox="0 0 97 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 22.4347L48.3572 42L97 22.4347L96.9487 0L48.3572 19.6069L0 0Z" fill="#979797" />
      <path d="M54.7827 32.1144L50.9254 33.7182L48 26.6826L48.7675 26.3635L51.427 32.7595L54.5167 31.4748L54.7827 32.1144Z" fill="white" />
      <path d="M58.034 23.8839L59.9856 28.5776C60.2557 29.227 60.1577 29.8795 59.213 30.2723L56.9596 31.2092C56.015 31.602 55.4833 31.2114 55.2132 30.5619L53.2616 25.8683C52.9916 25.2188 53.0896 24.5664 54.0342 24.1736L56.2876 23.2366C57.2322 22.8439 57.764 23.2344 58.034 23.8839ZM59.4391 29.4281L57.0456 23.6717L53.8082 25.0178L56.2017 30.7742L59.4391 29.4281Z" fill="white" />
      <path d="M64.665 24.3814L63.0513 25.0524L62.7853 24.4128L65.1272 23.439L66.191 25.9974C66.4611 26.6468 66.363 27.2993 65.4184 27.6921L63.2634 28.5881C62.3188 28.9809 61.787 28.5903 61.517 27.9408L59.5654 23.2472C59.2953 22.5977 59.3934 21.9453 60.338 21.5525L62.493 20.6565C63.4376 20.2637 63.9693 20.6543 64.2394 21.3037L64.5258 21.9925L63.859 22.4429L63.2903 21.0751L60.112 22.3967L62.5055 28.1531L65.6838 26.8315L64.665 24.3814Z" fill="white" />
      <path d="M65.9972 19.1994L68.9226 26.235L68.1551 26.5542L65.2297 19.5185L65.9972 19.1994Z" fill="white" />
      <path d="M68.7021 19.2057L69.3854 20.849C69.5327 21.2033 69.6974 21.2386 70.05 21.1151L71.785 20.486C72.746 20.1326 73.2655 20.4937 73.5355 21.1431L74.1738 22.6782C74.4438 23.3276 74.3458 23.98 73.4012 24.3728L71.5217 25.1543C70.5771 25.5471 70.0454 25.1565 69.7753 24.507L69.4807 23.7986L70.1434 23.3384L70.5894 24.4109C70.663 24.588 70.872 24.6743 71.0491 24.6006L73.3025 23.6637C73.4796 23.59 73.5659 23.3811 73.4922 23.2039L72.7517 21.4229C72.6125 21.0883 72.4675 21.0448 72.0952 21.1765L70.3602 21.8056C69.3992 22.159 68.8838 21.8078 68.6138 21.1583L68.0205 19.7315C67.7504 19.0821 67.8485 18.4296 68.7931 18.0369L70.4167 17.3618C71.3614 16.969 71.8931 17.3596 72.1631 18.009L72.4332 18.6585L71.7705 19.1187L71.3491 18.1051C71.2754 17.928 71.0664 17.8418 70.8893 17.9154L68.8918 18.746C68.7147 18.8197 68.6284 19.0286 68.7021 19.2057Z" fill="white" />
      <path d="M72.7242 17.1525L72.4583 16.5129L77.339 14.4835L77.6049 15.1231L75.5483 15.9782L78.2078 22.3742L77.4403 22.6934L74.7808 16.2974L72.7242 17.1525Z" fill="white" />
      <path d="M79.0179 13.7854L81.9433 20.821L81.1758 21.1401L78.2504 14.1045L79.0179 13.7854Z" fill="white" />
      <path d="M87.7306 18.4147L83.5781 20.1413L80.6527 13.1056L84.6576 11.4404L84.9236 12.08L81.6862 13.4261L82.7295 15.9353L84.5401 15.1825L84.8183 15.8516L83.0077 16.6044L84.0797 19.1825L87.4647 17.775L87.7306 18.4147Z" fill="white" />
      <path d="M86.5709 10.6449L87.9456 13.9511L87.9653 13.9429L89.2277 9.54017L90.0936 9.18012L88.7705 13.6312L93.452 16.0357L92.4089 16.4694L88.0676 14.1889L88.0479 14.1971L89.4963 17.6805L88.7288 17.9996L85.8034 10.964L86.5709 10.6449Z" fill="white" />
    </svg>
  )
}
