import Content from '~/components/elements/Content'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="relative">
      <div className="lg:grid grid-cols-1 lg:grid-cols-2 z-10 absolute top-0 left-0 w-full h-full">
        <div className="lg:col-span-1 relative h-full">
          <LossePlaatjie
            className="absolute opacity-5  md:opacity-30 top-0 left-0 object-cover h-full w-full"
            src={fields.image}
            maxWidth={2000}
            loading="eager"
            alt="banner-image"
          />
        </div>
        <div className="col-span-1 [background:linear-gradient(90deg,#5B5B5B_0%,#232323_100%)]" />
      </div>
      <div className="container relative z-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 max-w-screen-xl mx-auto">
          <div />
          <div className="col-span-1 md:px-10 pt-16 md:pt-28 pb-6 md:pb-10 lg:pl-32 lg:pt-32 lg:pr-20 lg:pb-16">
            <Content className="text-white">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
