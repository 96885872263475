import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import MiscShell from './Misc/MiscShell'
import MembersShell from './Members/MembersShell'
import ProjectsShell from './Projects/ProjecsShell'
import VacanciesShell from './Vacancies/VacanciesShell'
import AssignmentsShell from './Assignments/AssignmentsShell'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Misc`]: MiscShell,
    [`${prefix}_Flex_Members`]: MembersShell,
    [`${prefix}_Flex_Projects`]: ProjectsShell,
    [`${prefix}_Flex_Vacancies`]: VacanciesShell,
    [`${prefix}_Flex_Assignments`]: AssignmentsShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}
