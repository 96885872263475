import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function SmallCTA({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="SmallCTA" className={clsx(fields.dark ? 'bg-lm-shoe' : 'bg-lm-machine', 'section')}>
      <div className="bg-lm-cadmium py-5">
        <div className="container">
          <div className="max-w-screen-lg mx-auto gap-12 lg:gap-32 flex flex-col lg:flex-row justify-between">
            <div>
              <Content className="text-white children-p:text-xl children-p:font-secondary children-p:font-semibold">
                {fields.description}
              </Content>
            </div>
            <div className="flex items-center relative">
              <div
                className={clsx(
                  fields.dark ? 'bg-lm-shoe' : 'bg-lm-machine',
                  'w-[200%] lg:w-[20px] h-[10px] lg:h-[200%] lg:-rotate-[20deg] absolute -left-[50%] right-0 mx-auto lg:right-[unset] lg:-left-20 -top-[90%] lg:-top-[50%]'
                )}
              />
              <Button className="button-white-outline" to={fields.links![0]?.link?.url || '/'}>
                {fields.links![0]?.link?.title}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
