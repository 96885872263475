import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="TextDefault">
      <div className="container section">
        <div className="max-w-screen-lg mx-auto">
          <Content className="text-white">{fields?.description}</Content>
        </div>
      </div>
    </section>
  )
}
