import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function BrandSlider({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="BrandSlider" className="bg-white py-6 lg:py-12">
      <div className="container">
        <div className="max-w-screen-xl mx-auto flex relative flex-col gap-5">
          <Content className="children-h2:text-3xl">{fields.description}</Content>

          <div>
            <Slider
              mousewheel={{
                forceToAxis: true
              }}
              className="relative"
              slidesPerView={5}
              spaceBetween={20}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false
              }}
              loop
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 4,
                  spaceBetween: 30
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 5,
                  spaceBetween: 40
                }
              }}
            >
              {fields.brands?.map((brand, index) => (
                <Slide key={index}>
                  <LossePlaatjie maxWidth={1000} src={brand?.image} alt="brand-image" />
                </Slide>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
