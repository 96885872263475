import { useLoaderData } from '@ubo/losse-sjedel'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import OpenSollicitation from '~/components/elements/Modals/OpenSollicitation'
import Sollicitation from '~/components/elements/Modals/Sollicitation'
import type { Assignment, Page_Flexcontent_Flex_Text, Vacancy } from '~/graphql/types'

export default function BlogContent({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const { page, request } = useLoaderData<{ page: Vacancy | Assignment; request: any }>()

  return (
    <section id="BlogContent" className="bg-lm-shoe section">
      {/* @ts-ignore */}
      <Sollicitation fields={fields} />
      <OpenSollicitation fields={fields} />
      <div className="container">
        <div className="max-w-screen-lg mx-auto flex flex-col gap-5 lg:gap-11">
          {/* BACK BUTTON */}
          <div className="flex justify-start relative z-40">
            <Button to={page.contentType?.node.name === 'vacancy' ? '/vacatures/' : '/blog/'} className="button-white-outline">
              TERUG NAAR HET OVERZICHT
            </Button>
          </div>
          <div className="bg-lm-beluga relative gap-x-5 gap-y-10 lg:gap-11 px-4 md:px-10 lg:px-20 py-4 md:py-12 lg:py-16 shadow-lm-basic grid grid-cols-3 lg:grid-cols-5">
            {/* TITEL WITH TAGS */}
            <div className="col-span-3 lg:col-span-4 flex flex-col gap-4 lg:gap-12">
              <div className="flex flex-col gap-6">
                <div className="grid grid-cols-2 lg:grid-cols-3">
                  <h1 className="col-span-2 text-3xl md:text-4xl lg:text-5xl font-secondary font-semibold">{page.title}</h1>
                </div>
              </div>
            </div>
            {/* INTRO */}
            {/* GENERAL DESCRIPTION */}
            <div className="col-span-5">
              <Content className="first:children-h2:text-lm-cadmium children-h2:text-3xl children-a:text-lm-cadmium children-li:font-light children-li:text-lm-shoe">
                {fields.description}
              </Content>
            </div>
            {/* CTA BLOCK */}

            {/* SUB DESCRIPTION */}
            <div className="col-span-3 lg:col-span-5">
              <Content className="children-h2:text-3xl children-h2:mb-0 children-li:font-light children-li:text-lm-shoe">
                {fields.subdescription}
              </Content>
            </div>
            {/* SHARE */}
            <div className="col-span-3 lg:col-span-5 py-10 grid grid-cols-1 gap-6 lg:gap-4 lg:grid-cols-1">
              <div className="col-span-1">
                <Content className="text-lm-shoe children-h2:text-3xl children-h2:mb-0">{fields.share?.description}</Content>
              </div>
              <div className="col-span-1 flex flex-row gap-6">
                <LinkedinShareButton className="group" url={request.url}>
                  <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 23.1279C0 10.7015 10.0736 0.62793 22.5 0.62793C34.9264 0.62793 45 10.7015 45 23.1279C45 35.5543 34.9264 45.6279 22.5 45.6279C10.0736 45.6279 0 35.5543 0 23.1279ZM16.2817 34.7887V19.2412H10.8398V34.7887H16.2817ZM13.5608 16.9087C12.058 16.9087 10.8398 15.6905 10.8398 14.1877C10.8398 12.685 12.058 11.4668 13.5608 11.4668C15.0635 11.4668 16.2817 12.685 16.2817 14.1877C16.2817 15.6904 15.0636 16.9086 13.5609 16.9087H13.5608ZM29.4965 34.7886H34.1605L34.1607 26.2443C34.1607 22.0489 33.2557 19.2411 28.3653 19.2411C26.0149 19.2411 24.4374 20.1143 23.792 21.3393H23.7253V19.2411H19.3905V34.7886H23.9207V27.0822C23.9207 25.0504 24.3052 23.0823 26.8196 23.0823C29.2959 23.0823 29.4965 25.4063 29.4965 27.2131V34.7886Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </LinkedinShareButton>
                <FacebookShareButton className="group" url={request.url}>
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.1269 44.0771C25.9395 44.1119 25.7513 44.1439 25.5625 44.1732C25.7513 44.1439 25.9395 44.1119 26.1269 44.0771Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M26.5774 44.0047C26.4878 44.0234 26.3982 44.0406 26.3086 44.0582C26.3982 44.0406 26.4878 44.0234 26.5774 44.0047Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M25.06 44.2229C24.8406 44.251 24.6199 44.276 24.3984 44.2971C24.6199 44.276 24.8406 44.251 25.06 44.2229Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M25.493 44.1733C25.3876 44.1893 25.2815 44.2042 25.1758 44.2182C25.2815 44.2042 25.3876 44.1893 25.493 44.1733Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M27.1905 43.885C27.1115 43.9041 27.0322 43.9232 26.9529 43.9416C27.0322 43.9232 27.1115 43.9041 27.1905 43.885Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M28.7111 43.5139C28.6472 43.5346 28.5834 43.5541 28.5195 43.5744C28.5834 43.5541 28.6472 43.5346 28.7111 43.5139Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M28.2517 43.6373C28.1824 43.658 28.1127 43.6791 28.043 43.699C28.1127 43.6791 28.1824 43.6584 28.2517 43.6373Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M27.6495 43.7851C27.576 43.8046 27.5019 43.823 27.4277 43.8417C27.5019 43.823 27.576 43.8046 27.6495 43.7851Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M24.3928 44.2906C24.2743 44.302 24.1559 44.3121 24.0371 44.3211C24.1559 44.3121 24.2743 44.302 24.3928 44.2906Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M45 22.2614C45 10.056 34.9245 0.158203 22.5 0.158203C10.0755 0.158203 0 10.056 0 22.2614C0 34.4669 10.0755 44.3647 22.5 44.3647C22.6322 44.3647 22.7637 44.362 22.8955 44.3596V27.1532H18.0615V21.6189H22.8955V17.5464C22.8955 12.8233 25.8306 10.2527 30.1194 10.2527C32.1731 10.2527 33.9385 10.4031 34.4531 10.4702V15.4065H31.4957C29.1625 15.4065 28.7107 16.4958 28.7107 18.0942V21.6189H34.2897L33.5625 27.1532H28.7107V43.5107C38.1153 40.8622 45 32.3505 45 22.2614Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M23.9864 44.3191C23.7523 44.3363 23.5171 44.35 23.2812 44.359C23.5171 44.35 23.7523 44.3363 23.9864 44.3191Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M23.27 44.3563C23.1457 44.3609 23.0211 44.3645 22.8965 44.3668C23.0211 44.3645 23.1457 44.3609 23.27 44.3563Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </FacebookShareButton>
                <WhatsappShareButton className="group" url={request.url}>
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0894 30.6642L13.1525 31.9155L14.4321 28.1407C13.1631 26.4126 12.4794 24.3244 12.4807 22.1805C12.4807 16.5752 17.0772 12.0146 22.7261 12.0146C28.3749 12.0146 32.9709 16.5752 32.9709 22.1805C32.9709 27.7857 28.3744 32.3459 22.7255 32.3459C20.7228 32.3486 18.7633 31.7639 17.0894 30.6642ZM18.6234 16.6602C18.3179 16.668 18.0295 16.8022 17.8267 17.0308C17.809 17.0501 17.7891 17.0711 17.7675 17.094C17.4562 17.4237 16.7815 18.1384 16.7815 19.5024C16.7815 20.9553 17.8437 22.3595 17.9993 22.5653L18.0011 22.5676C18.0125 22.5826 18.0341 22.6145 18.0657 22.6611C18.4489 23.2251 20.2972 25.9463 23.1031 27.042C25.6196 28.0247 26.0518 27.9212 26.437 27.8289C26.5168 27.8098 26.5945 27.7912 26.6883 27.7828C27.2352 27.7338 28.4555 27.066 28.7043 26.3741C28.9531 25.6821 28.9538 25.0877 28.8787 24.9647C28.8182 24.8655 28.6771 24.7979 28.4677 24.6976C28.4177 24.6737 28.3639 24.6479 28.3063 24.6194C28.0074 24.4713 26.5393 23.7545 26.2651 23.6556C25.9909 23.5567 25.7912 23.5061 25.5925 23.8034C25.3938 24.1007 24.8209 24.767 24.6467 24.9651C24.4724 25.1632 24.2983 25.1877 23.9997 25.0396C23.951 25.0154 23.8847 24.9869 23.8028 24.9517C23.3825 24.771 22.5524 24.4141 21.5976 23.5688C20.9451 22.9708 20.3851 22.2792 19.936 21.5165C19.7616 21.22 19.9175 21.0596 20.0668 20.9121C20.1553 20.8243 20.2568 20.702 20.3585 20.5794C20.4112 20.5158 20.4641 20.4521 20.5151 20.3932C20.6378 20.2435 20.7384 20.0769 20.8136 19.8986C20.8944 19.7325 20.885 19.5368 20.7888 19.3792C20.7392 19.2807 20.4577 18.6009 20.2016 17.9826C20.0731 17.6723 19.951 17.3775 19.8679 17.1793C19.6487 16.6571 19.4298 16.6712 19.2616 16.6821C19.2388 16.6835 19.2169 16.685 19.196 16.685C19.1118 16.685 19.0218 16.6792 18.9288 16.6732C18.8294 16.6668 18.7264 16.6602 18.6234 16.6602Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5ZM22.7264 10.0889C15.9963 10.0889 10.5407 15.5023 10.5407 22.1804C10.538 24.3825 11.1429 26.5427 12.2889 28.4231L10.0898 34.9109L16.8362 32.7672C18.6414 33.7573 20.6676 34.2747 22.7265 34.2716C29.4565 34.2716 34.9118 28.8585 34.9118 22.1804C34.9118 15.5023 29.4565 10.0889 22.7264 10.0889Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </WhatsappShareButton>
                <EmailShareButton className="group" url={request.url}>
                  <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.4995 0.5C10.0736 0.5 0.000244141 10.5737 0.000244141 22.9995C0.000244141 35.4251 10.0736 45.4995 22.4995 45.4995C34.9253 45.4995 44.9995 35.4251 44.9995 22.9995C44.9995 10.5737 34.9253 0.5 22.4995 0.5ZM22.5002 8.40332L35.8147 16.7058H9.18579L22.5002 8.40332ZM35.9797 29.9634H35.9811V17.2336C35.9811 17.0925 35.9667 16.9558 35.9433 16.8213L23.2221 24.7537C23.2132 24.7593 23.204 24.7638 23.1947 24.7683C23.1877 24.7718 23.1806 24.7752 23.1738 24.7791C23.157 24.7884 23.1399 24.7974 23.1228 24.8059C23.0325 24.8523 22.9402 24.8896 22.8457 24.9141C22.8404 24.9156 22.835 24.9166 22.8296 24.9175C22.8252 24.9184 22.8209 24.9192 22.8165 24.9202C22.7127 24.9451 22.6075 24.96 22.5025 24.96H22.501H22.5003C22.3953 24.96 22.2901 24.9447 22.1863 24.9202C22.1766 24.918 22.1668 24.9168 22.1571 24.9141C22.0624 24.8896 21.9699 24.8523 21.88 24.8059C21.8715 24.8017 21.8629 24.7973 21.8544 24.7928C21.8459 24.7883 21.8374 24.7837 21.829 24.7791C21.8222 24.7753 21.8153 24.7719 21.8084 24.7685C21.7991 24.7639 21.7897 24.7594 21.7807 24.7537L9.05953 16.8213C9.03613 16.9558 9.02173 17.0928 9.02173 17.2336V29.9634C9.02173 31.2949 10.1008 32.3742 11.4325 32.3742H33.5689C34.9005 32.3742 35.9797 31.2951 35.9797 29.9634Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
