import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextWithIcon({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextWithIcon" className="bg-lm-shoe section">
      <div className="container">
        <div className="max-w-screen-lg mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-10">
          <div className="col-span-1 lg:col-span-2">
            <Content className="text-white">{fields.description}</Content>
          </div>
          <div className="flex lg:pl-20 flex-col justify-center gap-5">
            <h3 className="text-white text-xl font-secondary">{fields.iconlink?.title}</h3>
            <a href={fields.iconlink?.url || '/'} target="_blank" rel="noreferrer">
              <LossePlaatjie src={fields.image} alt="text-with-image" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
