import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function FadedImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="FadedImage" className="bg-lm-machine relative">
      <div className="absolute z-10 overflow-hidden h-full top-0 left-0 w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
        <div className="col-span-1 lg:block hidden" />
        <div className="col-span-1 relative">
          <div className="[background:linear-gradient(90deg,#454545_0%,rgba(90,90,90,0)_100%)] absolute h-full w-full left-0 top-0" />
          <LossePlaatjie maxWidth={1000} className="lg:opacity-100 opacity-20 h-full object-cover" src={fields.image} alt="faded-image" />
        </div>
      </div>
      <div className="container relative z-20 section">
        <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div className="col-span-1">
            <Content className="text-white">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
