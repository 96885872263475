import type { Post as PostType } from '~/graphql/types'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'

export default function Post({ data }: { data: PostType }) {
  return (
    <section id="Post" className="bg-white relative flex flex-col gap-4 lg:gap-6 p-6 lg:p-12">
      <div className="flex flex-col gap-4 lg:gap-6">
        <div className="grid grid-cols-3">
          <h3 className="text-xl col-span-2 md:text-2xl lg:text-3xl font-secondary font-semibold">{data.title}</h3>
        </div>
        <Content className="text-lm-shoe line-clamp-4 children-p:!leading-[1.4]">{data.recap?.excerpt}</Content>
      </div>
      <div className="flex justify-end">
        <Button className="button-cadmium-lg" to={data.uri || '/'}>
          LEES MEER
        </Button>
      </div>
    </section>
  )
}
