import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies } from '~/graphql/types'

export default function ChosenVacancies({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  return (
    <section id="ChosenVacancies" className="bg-lm-machine section">
      <div className="container">
        <div className="max-w-screen-xl mx-auto flex flex-col gap-8 lg:gap-16">
          <Content className="text-white">{fields.description}</Content>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-11">
            {fields.chosenvacancies?.map((vacancy, index) => {
              if (!vacancy) return null

              return <Preview key={index} fields={vacancy} />
            })}
          </div>
          <div className="flex justify-center">
            <Button className="button-cadmium" to={fields.link?.url || '/'}>
              {fields.link?.title}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
