import { useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import Content from '../elements/Content'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <div className="bg-lm-machine relative overflow-hidden lg:pt-0 pt-10">
      <div className="absolute h-[144px] -top-10 left-0 w-full footer-gradient" />
      <footer className="container text-white">
        <div className="pt-6 pb-20 lg:py-20 grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-32">
          <div className="col-span-1 lg:col-span-2">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-0 gap-6">
              <div>
                <div className="relative">
                  <h2 className="pb-3 lg:pb-8 text-2xl text-lm-trolley font-bold uppercase font-secondary">Menu</h2>
                  <div className="w-[800px] h-[1px] bg-lm-cadmium absolute bottom-0 right-0" />
                </div>

                <ul className="pt-3">
                  {footer?.menuFooter?.map((item: any, index: number) => (
                    <li className="py-1 lg:py-2" key={index}>
                      <LosseLink className="hover:underline uppercase font-light" to={item?.link?.url || '/'}>
                        {item?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <div className="relative">
                  <h2 className="pb-3 lg:pb-8 text-2xl text-lm-trolley font-bold uppercase font-secondary">Vakgebieden</h2>
                  <div className="w-[800px] h-[1px] bg-lm-cadmium absolute bottom-0 right-0" />
                </div>

                <ul className="pt-3">
                  {footer?.menuVakgebieden?.map((item: any, index: number) => (
                    <li className="py-1 lg:py-2" key={index}>
                      <LosseLink className="hover:underline uppercase font-light" to={item?.link?.url || '/'}>
                        {item?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col gap-10">
                <div>
                  <div className="relative">
                    <h2 className="pb-3 lg:pb-8 text-2xl text-lm-trolley font-bold uppercase font-secondary">LM voor klanten</h2>
                    <div className="w-[800px] h-[1px] bg-lm-cadmium absolute bottom-0 right-0" />
                  </div>

                  <ul className="pt-3">
                    {footer?.menuWhy?.map((item: any, index: number) => (
                      <li className="py-1 lg:py-2" key={index}>
                        <LosseLink className="hover:underline uppercase font-light" to={item?.link?.url || '/'}>
                          {item?.link?.title}
                        </LosseLink>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex flex-row gap-4 lg:gap-9">
                  <a className="group" target="_blank" rel="noreferrer" href={footer.linkedin?.url || '/'}>
                    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 23.1279C0 10.7015 10.0736 0.62793 22.5 0.62793C34.9264 0.62793 45 10.7015 45 23.1279C45 35.5543 34.9264 45.6279 22.5 45.6279C10.0736 45.6279 0 35.5543 0 23.1279ZM10.8391 34.7886H16.281V19.2411H10.8391V34.7886ZM13.5601 16.9089C12.0573 16.9089 10.8391 15.6907 10.8391 14.188C10.8391 12.6853 12.0573 11.4671 13.5601 11.4671C15.0628 11.4671 16.281 12.6853 16.281 14.188C16.281 15.6907 15.0629 16.9089 13.5602 16.9089H13.5601ZM29.4965 34.7886H34.1605L34.1607 26.2443C34.1607 22.0489 33.2557 19.2411 28.3653 19.2411C26.0149 19.2411 24.4374 20.1143 23.792 21.3393H23.7253V19.2411H19.3905V34.7886H23.9207V27.0822C23.9207 25.0504 24.3052 23.0823 26.8196 23.0823C29.2959 23.0823 29.4965 25.4063 29.4965 27.2131V34.7886Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                    </svg>
                  </a>
                  <a className="group" target="_blank" rel="noreferrer" href={footer.facebook?.url || '/'}>
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M26.1279 44.077C25.9404 44.107 25.7523 44.1347 25.5635 44.16C25.7523 44.1347 25.9404 44.107 26.1279 44.077Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M26.5779 44.0015C26.4883 44.0177 26.3987 44.0325 26.3091 44.0477C26.3987 44.0325 26.4883 44.0177 26.5779 44.0015Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M25.0608 44.2213C24.8414 44.2456 24.6206 44.2672 24.3992 44.2854C24.6206 44.2672 24.8414 44.2456 25.0608 44.2213Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M25.493 44.1694C25.3876 44.1832 25.2815 44.1961 25.1758 44.2082C25.2815 44.1961 25.3876 44.1832 25.493 44.1694Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M27.1905 43.882C27.1115 43.8985 27.0322 43.9151 26.9529 43.9309C27.0322 43.9151 27.1115 43.8985 27.1905 43.882Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M28.7106 43.5111C28.6468 43.5289 28.5829 43.5458 28.519 43.5633C28.5829 43.5458 28.6468 43.5289 28.7106 43.5111Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M28.251 43.6344C28.1816 43.6523 28.1119 43.6705 28.0422 43.6877C28.1119 43.6705 28.1816 43.6527 28.251 43.6344Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M27.6495 43.7823C27.576 43.7991 27.5019 43.815 27.4277 43.8312C27.5019 43.815 27.576 43.7991 27.6495 43.7823Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M24.3921 44.2861C24.2736 44.2959 24.1552 44.3047 24.0364 44.3124C24.1552 44.3047 24.2736 44.2959 24.3921 44.2861Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M45 22.2614C45 10.056 34.9245 0.158203 22.5 0.158203C10.0755 0.158203 0 10.056 0 22.2614C0 34.4669 10.0755 44.3647 22.5 44.3647C22.6322 44.3647 22.7637 44.362 22.8955 44.3596V27.1532H18.0615V21.6189H22.8955V17.5464C22.8955 12.8233 25.8306 10.2527 30.1194 10.2527C32.1731 10.2527 33.9385 10.4031 34.4531 10.4702V15.4065H31.4957C29.1625 15.4065 28.7107 16.4958 28.7107 18.0942V21.6189H34.2897L33.5625 27.1532H28.7107V43.5107C38.1153 40.8622 45 32.3505 45 22.2614Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M23.9867 44.3154C23.7525 44.3302 23.5174 44.342 23.2815 44.3498C23.5174 44.342 23.7525 44.3302 23.9867 44.3154Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        d="M23.2693 44.3505C23.145 44.3546 23.0204 44.3576 22.8958 44.3596C23.0204 44.3576 23.145 44.3546 23.2693 44.3505Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                    </svg>
                  </a>
                  <a className="group" target="_blank" rel="noreferrer" href={footer.instagram?.url || '/'}>
                    <svg width="45" height="47" viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <ellipse
                        cx="5.31663"
                        cy="5.34806"
                        rx="5.31663"
                        ry="5.34806"
                        transform="matrix(0.922648 -0.385643 0.380192 0.924908 15.6187 20.4884)"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M31.0337 11.4346H13.9663C11.7134 11.4346 10.6423 12.5145 10.6423 14.7863V31.9968C10.6423 34.2686 11.7134 35.3486 13.9663 35.3486H31.0337C33.2866 35.3486 34.3577 34.2686 34.3577 31.9968V14.7863C34.3577 12.5145 33.2866 11.4346 31.0337 11.4346ZM22.5457 30.7872C19.5785 30.7872 16.9035 28.9848 15.768 26.2206C14.6326 23.4563 15.2602 20.2745 17.3583 18.1589C19.4564 16.0432 22.6118 15.4103 25.3531 16.5553C28.0943 17.7003 29.8817 20.3977 29.8817 23.3897C29.8817 27.4752 26.5973 30.7872 22.5457 30.7872ZM28.9088 15.8756C29.2077 16.603 29.9116 17.0773 30.6924 17.0772C31.7586 17.0772 32.6228 16.2056 32.6228 15.1306C32.6228 14.3432 32.1525 13.6334 31.4311 13.3321C30.7097 13.0308 29.8793 13.1973 29.3272 13.7541C28.7751 14.3109 28.61 15.1482 28.9088 15.8756Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 23.3916C0 10.8611 10.0736 0.703125 22.5 0.703125C34.9264 0.703125 45 10.8611 45 23.3916C45 35.922 34.9264 46.08 22.5 46.08C10.0736 46.08 0 35.922 0 23.3916ZM32.3992 37.5041C34.6587 37.4974 36.4887 35.6521 36.4953 33.3736V13.4095C36.4887 11.1311 34.6587 9.2857 32.3992 9.27904H12.6008C10.3413 9.2857 8.5113 11.1311 8.50469 13.4095V33.3736C8.5113 35.6521 10.3413 37.4974 12.6008 37.5041H32.3992Z"
                        fill="white"
                        className="group-hover:fill-lm-cadmium smooth"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-5 lg:gap-10">
            <div className="flex flex-col gap-5">
              <svg className="w-[200px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768.89 173">
                <g id="Group_9" data-name="Group 9" transform="translate(-0.359 -0.458)">
                  <path
                    id="Fill_1"
                    data-name="Fill 1"
                    d="M0,96.111V0H9.945V87.374H49.978v8.737Z"
                    transform="translate(0.359 0.458)"
                    fill="#fff"
                  />
                  <path
                    id="Fill_2"
                    data-name="Fill 2"
                    d="M47.846,100H17.154c-6.017,0-10.526-1.635-13.4-4.861C1.263,92.345,0,88.381,0,83.357V16.643C0,11.619,1.263,7.655,3.753,4.861,6.629,1.635,11.137,0,17.154,0H47.846c6.018,0,10.527,1.635,13.4,4.861C63.738,7.654,65,11.618,65,16.643V83.357c0,5.025-1.262,8.989-3.752,11.782C58.373,98.365,53.864,100,47.846,100ZM10.454,9.09V90.91H54.546V9.09Z"
                    transform="translate(65.249 0.458)"
                    fill="#fff"
                  />
                  <path
                    id="Fill_4"
                    data-name="Fill 4"
                    d="M13.485,37.045c0,5.033,1.79,6.291,6.6,6.571L43.9,44.735c13.209.558,17.615,7.548,17.615,16.776V83.318c0,9.228-4.405,16.638-17.615,16.638H17.615C4.405,99.956,0,92.546,0,83.318V73.255l10.185-2.238V86.255a4.619,4.619,0,0,0,4.538,4.614H46.236a4.621,4.621,0,0,0,4.542-4.614V60.95c0-4.753-1.514-6.01-6.6-6.291L20.366,53.541C7.157,52.983,2.755,46.134,2.755,36.9V16.634C2.755,7.406,7.157,0,20.366,0H43.071C56.281,0,60.686,7.406,60.686,16.634v9.225L50.5,28.1V13.7a4.616,4.616,0,0,0-4.542-4.611H18.027A4.618,4.618,0,0,0,13.485,13.7Z"
                    transform="translate(150.293 0.458)"
                    fill="#fff"
                  />
                  <path
                    id="Fill_5"
                    data-name="Fill 5"
                    d="M0,0H69.2V9.086H40.043V99.956H29.16V9.086H0Z"
                    transform="translate(219.493 0.458)"
                    fill="#fff"
                  />
                  <path
                    id="Fill_6"
                    data-name="Fill 6"
                    d="M19.089,0,46.48,52.145,74.007,0H92.267V99.956H72.486V42.5h-.141L48.279,86.816H43.436L20.055,42.5h-.137V99.956H0V0Z"
                    transform="translate(296.382 0.458)"
                    fill="#f7951f"
                  />
                  <path id="Fill_7" data-name="Fill 7" d="M0,99.956H23.067V0H0Z" transform="translate(411.715 0.458)" fill="#f7951f" />
                  <path
                    id="Fill_8"
                    data-name="Fill 8"
                    d="M18.126,0,53.568,58.435H53.7V0H73.045V99.956h-16.1L19.614,38.306H19.48v61.65H0V0Z"
                    transform="translate(450.16 0.458)"
                    fill="#f7951f"
                  />
                  <path
                    id="Fill_9"
                    data-name="Fill 9"
                    d="M20.829,0V81.083H52.353V0H73.045V83.321c0,9.225-4.3,16.634-17.636,16.634H17.773C4.442,99.956,0,92.546,0,83.321V0Z"
                    transform="translate(538.582 0.458)"
                    fill="#f7951f"
                  />
                  <path
                    id="Fill_10"
                    data-name="Fill 10"
                    d="M0,0H69.2V18.453H44.979v81.5H24.218v-81.5H0Z"
                    transform="translate(627.005 0.458)"
                    fill="#f7951f"
                  />
                  <path
                    id="Fill_11"
                    data-name="Fill 11"
                    d="M0,99.956V0H63.226V18.453H21.313V40.124h26.14V59H21.313V81.5H65.356V99.956Z"
                    transform="translate(703.893 0.458)"
                    fill="#f7951f"
                  />
                  <path id="Fill_16" data-name="Fill 16" d="M0,3.844H768.89V0H0Z" transform="translate(0.359 169.614)" fill="#fff" />
                </g>
              </svg>
              <div className="lg:mt-6 flex items-center gap-3">
                <svg className="w-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 184">
                  <g id="maps-and-flags" transform="translate(0.548 -0.635)">
                    <path
                      id="Shape"
                      d="M62.046,182.013C59.506,179.18,0,112.243,0,66.641a66.5,66.5,0,1,1,133,0c0,45.6-59.515,112.539-62.046,115.372a5.986,5.986,0,0,1-8.909,0Z"
                      transform="translate(-0.548 0.635)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <Content className="font-light">{footer.address}</Content>
              </div>
              <div className="flex items-center gap-3">
                <svg className="w-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 184">
                  <g id="maps-and-flags" transform="translate(0.548 -0.635)">
                    <path
                      id="Shape"
                      d="M62.046,182.013C59.506,179.18,0,112.243,0,66.641a66.5,66.5,0,1,1,133,0c0,45.6-59.515,112.539-62.046,115.372a5.986,5.986,0,0,1-8.909,0Z"
                      transform="translate(-0.548 0.635)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <Content className="font-light">{footer?.secondAddress}</Content>
              </div>
              <div>
                <a className="font-light hover:underline flex items-center gap-3" href={footer.phone?.url || '/'}>
                  <svg className="w-[15px]" id="phone-call-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166 166">
                    <path
                      id="Path"
                      d="M152.895,109.3a94.9,94.9,0,0,1-29.775-4.736,12.758,12.758,0,0,0-12.957,2.744L91.318,121.538C69.694,110,55.855,96.167,44.471,74.7L58.318,56.3A13.223,13.223,0,0,0,61.56,42.933a94.864,94.864,0,0,1-4.77-29.828A13.121,13.121,0,0,0,43.684,0H13.105A13.121,13.121,0,0,0,0,13.105C0,97.407,68.593,166,152.895,166A13.121,13.121,0,0,0,166,152.895V122.4A13.121,13.121,0,0,0,152.895,109.3Z"
                      fill="#fff"
                    />
                  </svg>

                  {footer.phone?.title}
                </a>
              </div>
              <div>
                <a className="font-light flex items-center gap-3 hover:underline" href={footer.mail?.url || '/'}>
                  <svg className="w-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327 245.25">
                    <g id="email-6" transform="translate(0 -0.333)">
                      <path
                        id="Path"
                        d="M306.563,0H20.438A20.534,20.534,0,0,0,0,20.615v11.73l163.5,69.843L327,32.324V20.615A20.534,20.534,0,0,0,306.563,0Z"
                        transform="translate(0 0.333)"
                        fill="#fff"
                      />
                      <path
                        id="Path-2"
                        data-name="Path"
                        d="M167.485,72.848a9.448,9.448,0,0,1-7.971,0L0,0V161.9c0,12.167,9.156,22.042,20.438,22.042H306.563c11.281,0,20.438-9.875,20.438-22.042V0Z"
                        transform="translate(0 61.646)"
                        fill="#fff"
                      />
                    </g>
                  </svg>

                  {footer.mail?.title}
                </a>
              </div>
            </div>
            <div>
              <a
                target={footer.whatsapp?.target || '_blank'}
                rel="noreferrer"
                className="button-cadmium"
                href={footer.whatsapp?.url || '/'}
              >
                {footer.whatsapp?.title}
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-row text-sm gap-3 pb-10 opacity-50">
          <div>© 2023</div>
          <div>-</div>
          <LosseLink className="hover:underline" to="/privacyverklaring">
            Privacy verklaring
          </LosseLink>
          <div>-</div>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://admin.lostminute.nl/wp-content/uploads/2023/04/AIV-LM-Flex-03042023.pdf"
          >
            Algemene voorwaarden
          </a>
        </div>
      </footer>
    </div>
  )
}
