import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function BlockWithImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="BlockWithImage" className="bg-lm-machine section">
      <div className="container">
        <div className="max-w-screen-xl mx-auto">
          <div className="bg-white shadow p-8 lg:p-0 grid grid-cols-1 lg:grid-cols-5">
            <div className="pb-4 lg:py-20 lg:px-16">
              <h2 className="text-2xl font-semibold font-secondary">{fields.title}</h2>
            </div>
            <div className="col-span-1 lg:col-span-2 lg:py-20">
              <Content className="text-lm-spanish children-li:text-lm-spanish">{fields.description}</Content>
            </div>
            <div className="col-span-1 lg:col-span-2 relative">
              <LossePlaatjie
                maxWidth={1000}
                src={fields.image}
                className="absolute h-full w-full top-0 left-0 object-cover"
                alt="block-image"
              />
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl flex flex-col gap-6 mx-auto mt-10">
          <Content className="text-white">{fields.subdescription}</Content>
          <div className="flex justify-center">
            <Button className="button-cadmium-lg" to={fields.links![0]?.link?.url || '/'}>
              {fields.links![0]?.link?.title}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
