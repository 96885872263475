import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import BannerDefault from './BannerDefault'
import BannerField from './BannerField'
import BannerBig from './BannerBig'
import SmallCTA from './SmallCTA'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    field: BannerField,
    big: BannerBig,
    smallcta: SmallCTA
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
