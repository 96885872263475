import type { Project } from '~/graphql/types'
import Button from '../Button'
import Content from '../Content'

export default function Preview({ fields }: { fields: Project }) {
  return (
    <div className="bg-white lg:aspect-square px-8 py-8 lg:py-12 gap-6 flex justify-between flex-col">
      <div className="flex flex-col gap-6">
        <div className="font-secondary text-3xl font-semibold">{fields.title}</div>
        <Content className="text-lm-spanish">{fields.recap?.description}</Content>
      </div>
      <div className="flex justify-end">
        <Button className="button-bare" to={fields.uri || '/'}>
          Meer weten &gt;
        </Button>
      </div>
    </div>
  )
}
