import { Link, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerField({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerField" className="relative z-0">
      <LossePlaatjie
        maxWidth={2000}
        className="absolute -z-10 h-full w-full object-cover opacity-20 top-0 left-0"
        src={fields.backgroundimage}
        alt="background-image"
      />
      <div className="container section">
        <div className="max-w-screen-xl mx-auto w-full grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32">
          <div className="flex justify-center flex-col gap-10 section-sm">
            <Content className="text-white">{fields.description}</Content>
            <div>
              {fields.links?.map((link, index) => (
                <Link className="button-cadmium-lg" key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="aspect-square w-[220px] p-4 text-center text-white smooth uppercase font-secondary text-3xl border flex flex-col gap-4 justify-center items-center border-white">
              <LossePlaatjie className="w-32" src={fields.image} alt="icon" />
              {fields.field}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
