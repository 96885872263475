import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function Packets({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="Packets" className="bg-lm-shoe section">
      <div className="container">
        <div className="max-w-screen-xl mx-auto flex flex-col gap-6 lg:gap-20">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <Content className="text-white">{fields.description}</Content>
          </div>
          <div className="max-w-screen-lg mx-auto w-full grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32">
            {fields.items?.map((item, index) => (
              <div className="bg-white pt-6 pb-6 lg:pb-12 px-4 lg:px-10 flex flex-col justify-between gap-6 lg:gap-12" key={index}>
                <div className="flex flex-col gap-6 lg:gap-11">
                  <Content className="children-h3:text-3xl lg:children-h3:text-5xl">{item?.description}</Content>
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-3">
                      {item?.points?.map((point, pindex) => (
                        <div className="text-lm-spanish px-4 lg:px-10 flex items-center gap-3" key={pindex}>
                          <svg
                            className="min-w-[24px] min-h-[18px]"
                            width="24"
                            height="18"
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 8.44356L9.20237 14.6459L20.8483 3"
                              stroke="#F58A2E"
                              strokeWidth="5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {point?.point}
                        </div>
                      ))}
                    </div>
                    {(item?.extrapoints?.length || 0) > 0 && (
                      <>
                        <div className="flex justify-center">
                          {index === 0 ? (
                            <div className="font-bold text-lm-cadmium">Zelf regelen</div>
                          ) : (
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9.58203 5.82715H15.251V9.03516H9.58203V14.6748H6.37402V9.03516H0.705078V5.82715H6.37402V0.114258H9.58203V5.82715Z"
                                fill="#F58A2E"
                              />
                            </svg>
                          )}
                          {/* Zelf regelen */}
                        </div>
                        <div className="flex flex-col gap-3">
                          {item?.extrapoints?.map((point, pindex) => (
                            <div className="text-lm-spanish px-4 lg:px-10 flex items-center gap-3" key={pindex}>
                              <svg
                                className="min-w-[24px] min-h-[18px]"
                                width="24"
                                height="18"
                                viewBox="0 0 24 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 8.44356L9.20237 14.6459L20.8483 3"
                                  stroke="#F58A2E"
                                  strokeWidth="5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              {point?.extrapoint}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button className="text-lm-cadmium hover:underline font-bold font-main text-xl" to="/contact/">
                    MEER INFO &gt;
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
