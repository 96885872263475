import { prepareFormDuufField, useFormDuuf } from '@ubo/form-duuf';
import InfoBox from './InfoBox';

const errorMessages: {[key: string]: Record<string, unknown>;} = {
  nl: {
    required: 'Is verplicht',
    email: 'E-mailadres niet juist',
    consent: 'U dient akkoord te gaan met de voorwaarden',
    default: 'Er ging iets mis'
  },
  en: {
    required: 'Is required',
    email: 'Is incorrect',
    consent: 'You must agree to the terms',
    default: 'Something went wrong'
  }
};

interface ChoiceProps {
  text: string;
  value: string;
}

export default function CustomCheckboxField({ id, customMessage }: {id: number;customMessage: string;}) {
  const formduuf = useFormDuuf();
  const field = prepareFormDuufField(id);
  const choices = typeof field.data.choices === 'string' ? JSON.parse(field.data.choices) : field.data.choices;
  const type = field.data.type.toLowerCase();
  const errors = errorMessages[formduuf.language];

  // Conditional logic
  if (field.data.conditionalLogic) {
    // Whether or not to show the field
    if (field.data.conditionalLogic.actionType === 'SHOW') {
      let show = true;

      field.data.conditionalLogic.rules.forEach((rule: {fieldId: number;operator: string;value: string;}) => {
        if (rule.operator === 'IS') {
          if (formduuf.formik.values[`input_${rule.fieldId}`] !== rule.value) {
            show = false;
          }
        }
      });

      if (!show) {
        return null;
      }
    }
  }

  return (
    <div
      className={`form-duuf-group form-duuf-group-${type} form-duuf-checkboxes-${id}${field.data.cssClass ? ` ${field.data.cssClass}` : ''
      }`}>

      <label className="form-duuf-label d-block mb-0" htmlFor={id.toString()}>
        {field.data.label}
        {field.data.isRequired && <span className="form-duuf-label-mandatory">*</span>}
      </label>
      <div className={`form-duuf-error form-duuf-error-${field.data.type.toLowerCase()}`}>
        {errors[formduuf.formik.errors[id]] || formduuf.formik.errors[id]}
      </div>
      <div className={`form-duuf-choices-${id}`}>
        {choices.map((choice: ChoiceProps, index: number) => {
          const fieldNumber = index + 1;
          const isChecked = formduuf.formik.values[`${id}_${fieldNumber}`] ?
          formduuf.formik.values[`${id}_${fieldNumber}`] === choice.value :
          false;

          return (
            <div className="d-flex align-items-center flex items-center" key={choice.value}>
              <input
                type="checkbox"
                className={`form-duuf-checkbox form-duuf-checkbox-${type}`}
                id={choice.value}
                name={id.toString()}
                placeholder={field.data.placeholder}
                onBlur={formduuf.formik.handleBlur}
                onChange={(event) => {
                  formduuf.formik.handleChange({
                    target: {
                      name: `${event.target.name}_${fieldNumber}`,
                      value: !isChecked ? event.target.value : ''
                    }
                  });
                }}
                value={choice.value}
                checked={isChecked} />

              <label htmlFor={choice.value} className={`form-duuf-checkbox-label form-duuf-checkbox-${type}`}>
                {choice.text}
              </label>
              <div className="ml-3 group relative">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#F5E8E0" />
                  <path
                    d="M12.128 8.8C11.7227 8.8 11.3813 8.66667 11.104 8.4C10.8267 8.12267 10.688 7.776 10.688 7.36C10.688 6.95467 10.8267 6.61867 11.104 6.352C11.3813 6.07467 11.7227 5.936 12.128 5.936C12.544 5.936 12.8907 6.07467 13.168 6.352C13.4453 6.61867 13.584 6.95467 13.584 7.36C13.584 7.776 13.4453 8.12267 13.168 8.4C12.8907 8.66667 12.544 8.8 12.128 8.8ZM10.96 18V9.84H13.296V18H10.96Z"
                    fill="#543575" />

                </svg>
                <div className="group-hover:block hidden smooth w-[800px] bottom-[calc(100%_-_24px)] -left-[388px] absolute pb-8">
                  <InfoBox big description={JSON.parse(field.data.description)[index]} />
                </div>
              </div>
            </div>);

        })}
        {customMessage}
      </div>
    </div>);

}