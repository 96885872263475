import type { ComponentPropsWithoutRef } from 'react'
import { LosseLink } from '@ubo/losse-sjedel'

export interface ButtonProps extends ComponentPropsWithoutRef<any> {
  onClick?: () => void
  to?: string
}

export default function Button({ children, className = '', ...props }: ButtonProps) {
  return (
    <LosseLink className={`${className}`} {...props}>
      {children}
    </LosseLink>
  )
}
