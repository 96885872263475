import { useEffect } from 'react'
import { useLocation, useLosseLayout, useNavigate } from '@ubo/losse-sjedel'
import MobileHeaderMenu from './MobileHeaderMenu'

export default function HeaderMobile({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) {
  const layout = useLosseLayout()
  // const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
      layout.setScrollable(true)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <div className="flex h-[80px] bg-lm-shoe items-center justify-end lg:hidden w-full px-5">
      <svg
        onClick={() => {
          navigate('/')
        }}
        className="w-[100px] md:w-[140px] xl:w-[180px] h-[243px] left-4 md:left-10 -top-[90px] xl:-top-[50px] absolute"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="774.614"
        height="1047.55"
        viewBox="0 0 774.614 1047.55"
      >
        <defs>
          <clipPath id="clip-path">
            <path id="Clip_3" data-name="Clip 3" d="M0,0H774.614V1046.5H0Z" fill="none" />
          </clipPath>
        </defs>
        <g id="Group" transform="translate(-0.049)">
          <path
            id="Path_2"
            data-name="Path 2"
            d="M0,0V889.641l380.991,153.616L764.2,889.641V0Z"
            transform="translate(4.342)"
            fill="#2b2b2b"
          />
          <g id="Group_19" data-name="Group 19" transform="translate(0.049 1.05)">
            <path id="Fill_1" data-name="Fill 1" d="M0,77.6V0H8.4V70.543h33.8V77.6Z" transform="translate(80.872 185.886)" fill="#fff" />
            <g id="Group_18" data-name="Group 18" transform="translate(0)">
              <path id="Clip_3-2" data-name="Clip 3" d="M0,0H774.614V1046.5H0Z" fill="none" />
              <g id="Group_18-2" data-name="Group 18" clipPath="url(#clip-path)">
                <path
                  id="Fill_2"
                  data-name="Fill 2"
                  d="M39.145,78.511H14.038C7.634,78.511,0,76.244,0,65.447V13.064C0,7.1,2.435,0,14.038,0H39.145c6.4,0,14.034,2.266,14.034,13.064V65.447C53.179,71.407,50.744,78.511,39.145,78.511ZM8.555,7.137V71.374H44.628V7.137Z"
                  transform="translate(132.994 188.094)"
                  fill="#fff"
                />
                <path
                  id="Fill_4"
                  data-name="Fill 4"
                  d="M10.746,29.1c0,3.953,1.426,4.941,5.263,5.162l18.97.879c10.526.438,14.036,5.929,14.036,13.177V65.443c0,7.248-3.51,13.069-14.036,13.069H14.036C3.51,78.512,0,72.692,0,65.443v-7.9l8.116-1.758V67.751a3.653,3.653,0,0,0,3.616,3.624H36.843a3.656,3.656,0,0,0,3.619-3.624V47.874c0-3.733-1.206-4.721-5.263-4.941l-18.97-.879C5.7,41.616,2.2,36.237,2.2,28.986V13.066C2.2,5.817,5.7,0,16.229,0H34.322C44.848,0,48.358,5.817,48.358,13.066v7.246l-8.116,1.758V10.758a3.652,3.652,0,0,0-3.619-3.622H14.365a3.653,3.653,0,0,0-3.619,3.622Z"
                  transform="translate(200.532 188.095)"
                  fill="#fff"
                />
                <path
                  id="Fill_5"
                  data-name="Fill 5"
                  d="M0,0H54.387V7.137H31.472V78.512H22.918V7.137H0Z"
                  transform="translate(257.546 188.094)"
                  fill="#fff"
                />
                <path
                  id="Fill_6"
                  data-name="Fill 6"
                  d="M15.131,0,36.843,40.958,58.664,0H73.138V78.512H57.457V33.38h-.111L38.27,68.191H34.43L15.9,33.38h-.109V78.512H0V0Z"
                  transform="translate(320.589 188.094)"
                  fill="#f7951f"
                />
                <path id="Fill_7" data-name="Fill 7" d="M0,78.512H16.449V0H0Z" transform="translate(408.848 188.094)" fill="#f7951f" />
                <path
                  id="Fill_8"
                  data-name="Fill 8"
                  d="M14.694,0l28.73,45.9h.109V0h15.68V78.512H46.165L15.9,30.088h-.109V78.512H0V0Z"
                  transform="translate(440.425 188.094)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_9"
                  data-name="Fill 9"
                  d="M16.446,0V63.688H41.337V0H57.675V65.446c0,7.246-3.4,13.066-13.925,13.066H14.034C3.508,78.512,0,72.692,0,65.446V0Z"
                  transform="translate(514.544 188.094)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_10"
                  data-name="Fill 10"
                  d="M0,0H54.827V14.494H35.637V78.512H19.188V14.494H0Z"
                  transform="translate(580.765 188.094)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_11"
                  data-name="Fill 11"
                  d="M0,78.512V0H48.8V14.494H16.449V31.516H36.623V46.34H16.449V64.018h33.99V78.512Z"
                  transform="translate(644.355 188.094)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_12"
                  data-name="Fill 12"
                  d="M0,0V491.742l96.246-38.585V0Z"
                  transform="translate(598.55 383.004)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_13"
                  data-name="Fill 13"
                  d="M0,556.88l48.747,19.542,47.5-19.041V0H0Z"
                  transform="translate(338.559 383.004)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_14"
                  data-name="Fill 14"
                  d="M0,543.853l96.246-38.585V0H0Z"
                  transform="translate(468.555 383.004)"
                  fill="#f7951f"
                />
                <path
                  id="Fill_15"
                  data-name="Fill 15"
                  d="M96.246,0H0V453.156l224.991,90.193V438.442L96.246,387.2Z"
                  transform="translate(79.819 383.004)"
                  fill="#fff"
                />
                <path id="Fill_16" data-name="Fill 16" d="M0,2.721H614.977V0H0Z" transform="translate(79.818 323.301)" fill="#fff" />
                <path
                  id="Fill_17"
                  data-name="Fill 17"
                  d="M386.631,1046.5l-1.9-.827L0,891.062V0H8.151V885.542l378.686,152.183L766.463,885.539V0h8.151V891.065l-2.559,1.026L388.821,1045.719Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div>
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen)

            layout.setScrollable(false)
          }}
          className="z-10 h-12 w-8 text-center"
          aria-label="Open menu"
        >
          <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
          <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
          <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
        </button>
      </div>

      {isOpen && (
        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-lm-shoe">
          <div
            className="absolute right-5 top-5 text-center"
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)

              layout.setScrollable(true)
            }}
            aria-label="Close menu"
          >
            <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-white before:[content:''] after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-white after:[content:'']" />
          </div>
          <ul className="m-0 w-10/12 pl-0">
            <MobileHeaderMenu />
          </ul>
        </div>
      )}
    </div>
  )
}
