import { Link } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function BookmarksHorizontal({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="BookmarksHorizontal">
      <div className="bg-lm-shoe">
        <div className="container section-sm">
          <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="flex justify-center flex-col gap-10 lg:section-sm">
              <Content className="text-white">{fields.description}</Content>
              <div>
                {fields.links?.map((link, index) => (
                  <Link className="button-cadmium-lg" key={index} to={link?.link?.url || '/'}>
                    {link?.link?.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex flex-col gap-6 lg:gap-10 lg:left-0 left-[120px] lg:-top-[100px] relative">
                {fields.items?.map((item, index) => (
                  <div key={index} className="relative bg-lm-shoe h-[110px] w-[300px] ml-[30px] mr-[30px]">
                    {
                      {
                        0: (
                          <>
                            <div className="absolute h-0 w-0 bg-transparent top-[41px] -left-[214px] rotate-90 border-l-[55px] border-transparent border-r-[55px] border-t-[28px] border-t-white" />
                            <div className="absolute h-[110px] w-[2000px] bg-white -left-[145px]" />
                          </>
                        ),
                        1: (
                          <>
                            <div className="absolute h-0 w-0 rotate-[270deg] bg-transparent -left-[160px] top-[28px] border-l-[0] border-l-transparent border-r-white border-r-[111px] border-t-[55px] border-t-transparent" />
                            <div className="absolute h-[110px] w-[2000px] bg-white -left-[77px]" />
                          </>
                        ),
                        2: (
                          <>
                            <div className="absolute h-0 w-0 rotate-[270deg] bg-transparent -left-[90px] top-[28px] border-l-[0] border-l-transparent border-r-white border-r-[111px] border-t-[55px] border-t-transparent" />
                            <div className="absolute h-[110px] w-[2000px] bg-white -left-[7px]" />
                          </>
                        )
                      }[index]
                    }
                    <div className="absolute top-[1px] left-[1px] bg-lm-shoe h-[108px] w-[300px]">
                      {
                        {
                          0: (
                            <>
                              <div className="absolute h-0 w-0 bg-transparent top-[41px] -left-[213px] rotate-90 border-l-[54px] border-transparent border-r-[54px] border-t-[27px] border-t-lm-shoe" />
                              <div className="absolute h-[108px] w-[2000px] bg-lm-shoe -left-[146px]" />
                            </>
                          ),
                          1: (
                            <>
                              <div className="absolute h-0 w-0 rotate-[270deg] bg-transparent top-[27px] -left-[159px] border-l-[0] border-l-transparent border-r-lm-shoe border-r-[108px] border-t-[54px] border-t-transparent" />
                              <div className="absolute h-[108px] w-[2000px] bg-lm-shoe -left-[78px]" />
                            </>
                          ),
                          2: (
                            <>
                              <div className="absolute h-0 w-0 rotate-[270deg] bg-transparent top-[27px] -left-[89px] border-l-[0] border-l-transparent border-r-lm-shoe border-r-[108px] border-t-[54px] border-t-transparent" />
                              <div className="absolute h-[108px] w-[2000px] bg-lm-shoe -left-[8px]" />
                            </>
                          )
                        }[index]
                      }
                      <div
                        className={clsx(
                          index === 0 && '-left-[120px]',
                          index === 1 && '-left-[50px]',
                          index === 2 && 'left-[20px]',
                          'text-left top-0 h-full flex items-center lg:text-right text-2xl sm:text-3xl md:text-3xl lg:text-[50px] font-bold uppercase text-white font-secondary absolute w-[480px] z-[10]'
                        )}
                      >
                        {item?.link?.title}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
