import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section data-component="FormContact" className="section bg-lm-machine">
      <div className="container">
        <div className="max-w-screen-xl mx-auto grid grid-cols-3 lg:grid-cols-7 gap-4 lg:gap-8">
          <div className="col-span-3 xl:px-28 py-8 lg:pt-28 lg:pb-14 flex flex-col gap-10">
            <div className="flex flex-col gap-6">
              <svg width="200" height="45" viewBox="0 0 200 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 25V0H2.58685V22.7274H13V25H0Z" fill="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.7342 23.6365H31.2658V2.36347H19.7342V23.6365ZM34 21.6731C34 24.0726 32.8787 26 29.514 26H21.4869C18.1222 26 17 24.0726 17 21.6731V4.32688C17 1.92736 18.1222 0 21.4869 0H29.514C32.8787 0 34 1.92736 34 4.32688V21.6731Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M42.5078 9.6359C42.5078 10.9451 42.9734 11.2722 44.2257 11.3452L50.4182 11.6363C53.8541 11.7813 55 13.5997 55 16.0001V21.6722C55 24.0726 53.8541 26 50.4182 26H43.5818C40.1459 26 39 24.0726 39 21.6722V19.0547L41.6492 18.4726V22.4363C41.6492 23.0905 42.1858 23.6365 42.8297 23.6365H51.0266C51.6705 23.6365 52.208 23.0905 52.208 22.4363V15.8541C52.208 14.6178 51.8142 14.2908 50.49 14.2178L44.2976 13.9267C40.8616 13.7817 39.7166 12.0003 39.7166 9.59896V4.32688C39.7166 1.92646 40.8616 0 44.2976 0H50.2035C53.6395 0 54.7854 1.92646 54.7854 4.32688V6.72639L52.1361 7.30847V3.56278C52.1361 2.90861 51.5996 2.36347 50.9548 2.36347H43.6891C43.0452 2.36347 42.5078 2.90861 42.5078 3.56278V9.6359Z"
                  fill="white"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M57 0H75V2.36347H67.4158V26H64.5851V2.36347H57V0Z" fill="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M81.9653 0L89.0901 13.5636L96.2505 0H101V26H95.8546V11.0542H95.818L89.5581 22.5823H88.2983L82.2167 11.0542H82.1811V26H77V0H81.9653Z"
                  fill="#F7951F"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M107 26H113V0H107V26Z" fill="#F7951F" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M121.715 0L130.934 15.1999H130.969V0H136V26H131.813L122.102 9.96389H122.067V26H117V0H121.715Z"
                  fill="#F7951F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M145.418 0V21.091H153.618V0H159V21.6731C159 24.0726 157.88 26 154.413 26H144.623C141.156 26 140 24.0726 140 21.6731V0H145.418Z"
                  fill="#F7951F"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M163 0H181V4.79993H174.7V26H169.299V4.79993H163V0Z" fill="#F7951F" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M183 26V0H199.446V4.79993H188.544V10.4369H195.343V15.3459H188.544V21.2001H200V26H183Z"
                  fill="#F7951F"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 45H200V44H0V45Z" fill="white" />
              </svg>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3 text-white font-main font-light">
                  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 6.88105C0 3.08684 3.14018 0 7 0C10.8598 0 13.9999 3.08684 14 6.88105C14 11.5898 7.73569 18.5025 7.46898 18.7945C7.21892 19.0683 6.78154 19.0687 6.53102 18.7945C6.26431 18.5025 0 11.5898 0 6.88105ZM3.47852 6.88096C3.47852 8.78994 5.05838 10.343 7.00035 10.343C8.94229 10.343 10.5222 8.78998 10.5222 6.88099C10.5222 4.97201 8.94229 3.41895 7.00035 3.41895C5.05842 3.41895 3.47852 4.97197 3.47852 6.88096Z"
                      fill="white"
                    />
                  </svg>

                  {fields.socials?.address}
                </div>
                <div className="flex items-center gap-3 text-white font-main font-light">
                  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 6.88105C0 3.08684 3.14018 0 7 0C10.8598 0 13.9999 3.08684 14 6.88105C14 11.5898 7.73569 18.5025 7.46898 18.7945C7.21892 19.0683 6.78154 19.0687 6.53102 18.7945C6.26431 18.5025 0 11.5898 0 6.88105ZM3.47852 6.88096C3.47852 8.78994 5.05838 10.343 7.00035 10.343C8.94229 10.343 10.5222 8.78998 10.5222 6.88099C10.5222 4.97201 8.94229 3.41895 7.00035 3.41895C5.05842 3.41895 3.47852 4.97197 3.47852 6.88096Z"
                      fill="white"
                    />
                  </svg>

                  {fields.socials?.secondAddress}
                </div>
                <a
                  rel="noreferrer"
                  href={fields.socials?.phone?.url || '/'}
                  className="flex hover:underline items-center gap-3 text-white font-main font-light"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5789 11.8516C15.4686 11.8516 14.3811 11.6782 13.3503 11.3381C12.8473 11.1647 12.276 11.2983 11.9454 11.6356L9.9019 13.1789C7.55716 11.9274 6.05653 10.4277 4.82212 8.10001L6.32369 6.10485C6.70169 5.72685 6.83718 5.17357 6.67518 4.65537C6.33222 3.618 6.15791 2.52948 6.15791 1.42105C6.15791 0.637565 5.52035 0 4.73686 0H1.42105C0.637565 0 0 0.637565 0 1.42105C0 10.5622 7.4378 18 16.5789 18C17.3624 18 18 17.3624 18 16.5789V13.2726C18 12.4892 17.3624 11.8516 16.5789 11.8516Z"
                      fill="white"
                    />
                  </svg>

                  {fields.socials?.phone?.title}
                </a>
                <a
                  rel="noreferrer"
                  href={fields.socials?.mail?.url || '/'}
                  className="flex hover:underline items-center gap-3 text-white font-main font-light"
                >
                  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 0H1C0.448 0 0 0.451886 0 1.00867V1.58261L8 5L16 1.5816V1.00867C16 0.451886 15.552 0 15 0Z" fill="white" />
                    <path
                      d="M8.195 6.56441C8.133 6.59353 8.066 6.60755 8 6.60755C7.934 6.60755 7.867 6.59353 7.805 6.56441L0 3V10.9215C0 11.5168 0.448 12 1 12H15C15.552 12 16 11.5168 16 10.9215V3L8.195 6.56441Z"
                      fill="white"
                    />
                  </svg>

                  {fields.socials?.mail?.title}
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-9">
              <div className="flex justify-start gap-11">
                <a target="_blank" className="group" rel="noreferrer" href={fields.socials?.instagram?.url || '/'}>
                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse
                      rx="5.31663"
                      ry="5.34806"
                      transform="matrix(0.922648 -0.385643 0.380192 0.924908 23.5436 22.6813)"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M32.0198 10.7314H14.9523C12.6995 10.7314 11.6284 11.8114 11.6284 14.0832V31.2937C11.6284 33.5655 12.6995 34.6455 14.9523 34.6455H32.0198C34.2727 34.6455 35.3438 33.5655 35.3438 31.2937V14.0832C35.3438 11.8114 34.2727 10.7314 32.0198 10.7314ZM23.5318 30.0841C20.5646 30.0841 17.8896 28.2817 16.7541 25.5175C15.6187 22.7532 16.2463 19.5714 18.3444 17.4558C20.4425 15.3401 23.5978 14.7072 26.3391 15.8522C29.0804 16.9972 30.8678 19.6946 30.8678 22.6866C30.8678 26.7721 27.5833 30.0841 23.5318 30.0841ZM29.8949 15.1725C30.1937 15.8999 30.8977 16.3742 31.6785 16.3741C32.7447 16.3741 33.6089 15.5025 33.6089 14.4274C33.6089 13.6401 33.1386 12.9302 32.4172 12.6289C31.6958 12.3276 30.8654 12.4942 30.3133 13.051C29.7612 13.6077 29.5961 14.4451 29.8949 15.1725Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.986328 22.6884C0.986328 10.158 11.0599 0 23.4863 0C35.9127 0 45.9863 10.158 45.9863 22.6884C45.9863 35.2189 35.9127 45.3769 23.4863 45.3769C11.0599 45.3769 0.986328 35.2189 0.986328 22.6884ZM33.3855 36.801C35.645 36.7943 37.475 34.9489 37.4816 32.6705V12.7064C37.475 10.4279 35.645 8.58258 33.3855 8.57591H13.5872C11.3277 8.58258 9.49762 10.4279 9.49101 12.7064V32.6705C9.49762 34.9489 11.3277 36.7943 13.5872 36.801H33.3855Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </a>
                <a target="_blank" className="group" rel="noreferrer" href={fields.socials?.facebook?.url || '/'}>
                  <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M27.1142 44.3728C26.9268 44.4028 26.7386 44.4305 26.5498 44.4557C26.7386 44.4305 26.9268 44.4028 27.1142 44.3728Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M27.5642 44.2974C27.4746 44.3136 27.385 44.3284 27.2954 44.3436C27.385 44.3284 27.4746 44.3136 27.5642 44.2974Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M26.0473 44.5172C25.8279 44.5415 25.6072 44.5631 25.3857 44.5813C25.6072 44.5631 25.8279 44.5415 26.0473 44.5172Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M26.4793 44.4654C26.3739 44.4793 26.2679 44.4921 26.1621 44.5042C26.2679 44.4921 26.3739 44.4793 26.4793 44.4654Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M28.177 44.1779C28.0981 44.1944 28.0188 44.211 27.9395 44.2268C28.0188 44.211 28.0981 44.1944 28.177 44.1779Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M29.6969 43.8071C29.6331 43.8249 29.5692 43.8418 29.5054 43.8593C29.5692 43.8418 29.6331 43.8249 29.6969 43.8071Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M29.2375 43.9303C29.1682 43.9482 29.0985 43.9664 29.0288 43.9836C29.0985 43.9664 29.1682 43.9486 29.2375 43.9303Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M28.6358 44.078C28.5624 44.0949 28.4882 44.1108 28.4141 44.1269C28.4882 44.1108 28.5624 44.0949 28.6358 44.078Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M25.3781 44.582C25.2597 44.5918 25.1413 44.6006 25.0225 44.6083C25.1413 44.6006 25.2597 44.5918 25.3781 44.582Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M45.9863 22.5573C45.9863 10.3519 35.9108 0.454102 23.4863 0.454102C11.0618 0.454102 0.986328 10.3519 0.986328 22.5573C0.986328 34.7628 11.0618 44.6606 23.4863 44.6606C23.6185 44.6606 23.75 44.6579 23.8818 44.6555V27.4491H19.0479V21.9148H23.8818V17.8423C23.8818 13.1192 26.8169 10.5486 31.1057 10.5486C33.1594 10.5486 34.9248 10.699 35.4395 10.7661V15.7024H32.4821C30.1488 15.7024 29.697 16.7917 29.697 18.3901V21.9148H35.276L34.5489 27.4491H29.697V43.8066C39.1017 41.1581 45.9863 32.6464 45.9863 22.5573Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M24.9728 44.6114C24.7386 44.6263 24.5034 44.6381 24.2676 44.6458C24.5034 44.6381 24.7386 44.6263 24.9728 44.6114Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M24.2559 44.6465C24.1316 44.6506 24.007 44.6536 23.8823 44.6556C24.007 44.6536 24.1316 44.6506 24.2559 44.6465Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </a>
                <a target="_blank" className="group" rel="noreferrer" href={fields.socials?.linkedin?.url || '/'}>
                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.986328 23.4248C0.986328 10.9984 11.0599 0.924805 23.4863 0.924805C35.9127 0.924805 45.9863 10.9984 45.9863 23.4248C45.9863 35.8512 35.9127 45.9248 23.4863 45.9248C11.0599 45.9248 0.986328 35.8512 0.986328 23.4248ZM11.8255 35.0855H17.2673V19.538H11.8255V35.0855ZM14.5464 17.2058C13.0437 17.2058 11.8255 15.9876 11.8255 14.4849C11.8255 12.9821 13.0437 11.7639 14.5464 11.7639C16.0491 11.7639 17.2673 12.9821 17.2673 14.4849C17.2673 15.9876 16.0492 17.2057 14.5465 17.2058H14.5464ZM30.4828 35.0855H35.1469L35.147 26.5412C35.147 22.3457 34.2421 19.538 29.3516 19.538C27.0012 19.538 25.4237 20.4112 24.7784 21.6362H24.7116V19.538H20.3768V35.0855H24.9071V27.379C24.9071 25.3472 25.2916 23.3792 27.8059 23.3792C30.2823 23.3792 30.4828 25.7032 30.4828 27.51V35.0855Z"
                      fill="white"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </a>
              </div>
              <div className="flex justify-start">
                <a className="button-cadmium-lg w-[unset]" target="_blank" rel="noreferrer" href={fields.socials?.whatsapp?.url || '/'}>
                  {fields.socials?.whatsapp?.title}
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <h2 className="text-white text-xl font-secondary font-semibold">Openingstijden</h2>
              <div>
                {fields.openingtimes?.map((ot, index) => (
                  <div className="grid grid-cols-2 text-white font-main" key={index}>
                    <div className="font-semibold">{ot?.day}</div>
                    <div className="font-light">{ot?.time}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-3 lg:col-span-4 lg:pt-24 pt-8">
            <div className="bg-white p-6 lg:px-16 xl:px-20 lg:py-10 shadow">
              <Content>{fields?.description}</Content>

              <div className="mt-5">
                {/* @ts-ignore */}
                <Form
                  data={fields.form}
                  privacyUrl="/privacyverklaring/"
                  privacyUrlPlacement="Privacy Voorwaarden"
                  renderLoading={() => <Loading />}
                  renderStatus={({ status, content }) => <Content>{content}</Content>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
