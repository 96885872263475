import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import Projects from './Projects'

export default function ProjectsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: Projects
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
