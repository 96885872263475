import { useFormDuuf } from '@ubo/form-duuf'
import { useNavigate } from '@ubo/losse-sjedel'
import type { Dispatch, SetStateAction } from 'react'

export default function CloseForm({
  setStep,
  setShow
}: {
  setStep: Dispatch<SetStateAction<number>>
  setShow: Dispatch<SetStateAction<boolean>>
}) {
  const formduuf = useFormDuuf()
  const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        formduuf.formik.resetForm()
        navigate('#', {
          state: {
            scroll: false
          }
        })
        setStep(0)
        setShow(false)
      }}
      className="cursor-pointer w-[44px] flex justify-center items-center hover:bg-lm-cadmium smooth group"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <path
          className="group-hover:stroke-white smooth"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-width="1.5"
          d="M20 20L4 4m16 0L4 20"
        />
      </svg>
    </div>
  )
}
