import { prepareFormDuufField, useFormDuuf } from '@ubo/form-duuf';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

const errorMessages: {[key: string]: Record<string, unknown>;} = {
  nl: {
    required: 'Is verplicht',
    email: 'E-mailadres niet juist',
    consent: 'U dient akkoord te gaan met de voorwaarden',
    default: 'Er ging iets mis'
  },
  en: {
    required: 'Is required',
    email: 'Is incorrect',
    consent: 'You must agree to the terms',
    default: 'Something went wrong'
  }
};

const inputTypes: {[key: string]: string;} = {
  phone: 'tel'
};

export default function CustomTextField({ id, step, setStep }: {id: number;step: number;setStep: Dispatch<SetStateAction<number>>;}) {
  const formduuf = useFormDuuf();
  const field = prepareFormDuufField(id);
  const type = field.data.type.toLowerCase();
  const errors = errorMessages[formduuf.language];
  const [customLabel, setCustomLabel] = useState('');
  const [customText, setCustomText] = useState('');


  useEffect(() => {
    if (field.data.label?.includes('{{') && field.data.label?.includes('}}')) {

      const labelFirst = field.data.label.split('{{')[0];
      const labelSecond = field.data.label.split('}}')[1];
      const value = field.data.label.split('{{')[1].split('}}')[0];

      if (formduuf.formik.values[value]) {
        setCustomLabel(`${labelFirst} ${formduuf.formik.values[value]}${labelSecond}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customLabel, field.data.label]);

  // Conditional logic
  if (field.data.conditionalLogic) {
    // Whether or not to show the field
    if (field.data.conditionalLogic.actionType === 'SHOW') {
      let show = true;

      field.data.conditionalLogic.rules.forEach((rule: {fieldId: number;operator: string;value: string;}) => {
        if (rule.operator === 'IS') {
          if (formduuf.formik.values[`input_${rule.fieldId}`] !== rule.value) {
            show = false;
          }
        }
      });

      if (!show) {
        return null;
      }
    }
  }


  return (
    <div className={`form-duuf-group col-span-12 form-duuf-group-${type}${field.data.cssClass ? ` ${field.data.cssClass}` : ''}`}>
      <label className="form-duuf-label d-block mb-0" htmlFor={id.toString()}>
        {field?.fieldId === "input_28" ?
        field.data.label :

        customLabel !== '' ? customLabel : field.data.label}

        {field.data.isRequired && <span className="form-duuf-label-mandatory">*</span>}
      </label>
      <div className={`form-duuf-error form-duuf-error-${field.data.type.toLowerCase()}`}>
        {errors[formduuf.formik.errors[id]] || formduuf.formik.errors[id]}
      </div>
      <input
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            setStep(step + 1);
          }
        }}
        className={`form-duuf-input form-duuf-input-${type}`}
        type={inputTypes[type] || type}
        id={field.fieldId}
        name={field.fieldId}
        placeholder={field.data.placeholder}
        onBlur={formduuf.formik.handleBlur}
        onChange={formduuf.formik.handleChange}
        value={formduuf.formik.values[field.fieldId]}
        maxLength={field.data.maxLength === 0 ? undefined : field.data.maxLength} />

    </div>);

}