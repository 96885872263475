import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Preview from '~/components/elements/Projects/Preview'
import type { Page_Flexcontent_Flex_Projects, Project } from '~/graphql/types'

export default function Projects({ fields }: { fields: Page_Flexcontent_Flex_Projects }) {
  return (
    <section id="Projects" className="bg-lm-shoe section">
      <div className="container">
        <div className="max-w-screen-xl mx-auto flex flex-col gap-8 lg:gap-20">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <Content className="text-white">{fields.description}</Content>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-20">
            {fields.projects?.edges.map((edge, index) => {
              const project = edge?.node as Project

              return <Preview key={index} fields={project} />
            })}
          </div>
          <div className="flex justify-center flex-col items-center gap-6">
            <Content className="text-white">{fields.subtext}</Content>
            <Button className="button-cadmium" to={fields.link?.url || '/'}>
              {fields.link?.title}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
