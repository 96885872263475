import { type Dispatch, type SetStateAction, useState } from 'react'
import { Link, LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = useState(-1)

  return (
    <div className="flex flex-col w-full">
      <div className="bg-lm-trolley lg:block hidden">
        <div className="container h-[50px] flex flex-row gap-3 justify-end items-center">
          <a className="button-white-outline !text-sm" href={header.menuTopper?.phone?.url || '/'}>
            <svg width="20" height="20" viewBox="0 0 24 24">
              <path
                fill="#ffffff"
                d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2z"
              />
            </svg>
            {header.menuTopper?.phone?.title}
          </a>
          <Link className="button-cadmium !text-sm uppercase" to={header.menuTopper?.button?.url || '/'}>
            {header.menuTopper?.button?.title}
          </Link>
        </div>
      </div>
      <div className="absolute h-[70px] lg:h-[144px] top-[80px] lg:top-[50px] left-0 w-full header-gradient" />

      <div className="hidden lg:flex flex-row gap-5 container relative h-[80px] lg:h-[50px] items-center justify-start lg:justify-between w-full">
        <ul className="mb-0 hidden w-[calc(100%_-_90px)] gap-2 xl:gap-8 list-none items-center justify-end lg:flex">
          {header?.menuHeader?.slice(0, 3).map((l, index) => (
            <NavButton key={index} setOpen={setOpen} open={open} l={l} index={index} />
          ))}
        </ul>
        <Link to="/" className="lg:!w-[480px] flex">
          <svg
            className="lg:block hidden w-[140px] xl:w-[180px] h-[243px] lg:left-0 lg:right-0 lg:mx-auto -top-[90px] xl:-top-[50px] absolute "
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="774.614"
            height="1047.55"
            viewBox="0 0 774.614 1047.55"
          >
            <defs>
              <clipPath id="clipPath">
                <path id="Clip_3" data-name="Clip 3" d="M0,0H774.614V1046.5H0Z" fill="none" />
              </clipPath>
            </defs>
            <g id="Group" transform="translate(-0.049)">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M0,0V889.641l380.991,153.616L764.2,889.641V0Z"
                transform="translate(4.342)"
                fill="#2b2b2b"
              />
              <g id="Group_19" data-name="Group 19" transform="translate(0.049 1.05)">
                <path
                  id="Fill_1"
                  data-name="Fill 1"
                  d="M0,77.6V0H8.4V70.543h33.8V77.6Z"
                  transform="translate(80.872 185.886)"
                  fill="#fff"
                />
                <g id="Group_18" data-name="Group 18" transform="translate(0)">
                  <path id="Clip_3-2" data-name="Clip 3" d="M0,0H774.614V1046.5H0Z" fill="none" />
                  <g id="Group_18-2" data-name="Group 18" clipPath="url(#clip-path)">
                    <path
                      id="Fill_2"
                      data-name="Fill 2"
                      d="M39.145,78.511H14.038C7.634,78.511,0,76.244,0,65.447V13.064C0,7.1,2.435,0,14.038,0H39.145c6.4,0,14.034,2.266,14.034,13.064V65.447C53.179,71.407,50.744,78.511,39.145,78.511ZM8.555,7.137V71.374H44.628V7.137Z"
                      transform="translate(132.994 188.094)"
                      fill="#fff"
                    />
                    <path
                      id="Fill_4"
                      data-name="Fill 4"
                      d="M10.746,29.1c0,3.953,1.426,4.941,5.263,5.162l18.97.879c10.526.438,14.036,5.929,14.036,13.177V65.443c0,7.248-3.51,13.069-14.036,13.069H14.036C3.51,78.512,0,72.692,0,65.443v-7.9l8.116-1.758V67.751a3.653,3.653,0,0,0,3.616,3.624H36.843a3.656,3.656,0,0,0,3.619-3.624V47.874c0-3.733-1.206-4.721-5.263-4.941l-18.97-.879C5.7,41.616,2.2,36.237,2.2,28.986V13.066C2.2,5.817,5.7,0,16.229,0H34.322C44.848,0,48.358,5.817,48.358,13.066v7.246l-8.116,1.758V10.758a3.652,3.652,0,0,0-3.619-3.622H14.365a3.653,3.653,0,0,0-3.619,3.622Z"
                      transform="translate(200.532 188.095)"
                      fill="#fff"
                    />
                    <path
                      id="Fill_5"
                      data-name="Fill 5"
                      d="M0,0H54.387V7.137H31.472V78.512H22.918V7.137H0Z"
                      transform="translate(257.546 188.094)"
                      fill="#fff"
                    />
                    <path
                      id="Fill_6"
                      data-name="Fill 6"
                      d="M15.131,0,36.843,40.958,58.664,0H73.138V78.512H57.457V33.38h-.111L38.27,68.191H34.43L15.9,33.38h-.109V78.512H0V0Z"
                      transform="translate(320.589 188.094)"
                      fill="#f7951f"
                    />
                    <path id="Fill_7" data-name="Fill 7" d="M0,78.512H16.449V0H0Z" transform="translate(408.848 188.094)" fill="#f7951f" />
                    <path
                      id="Fill_8"
                      data-name="Fill 8"
                      d="M14.694,0l28.73,45.9h.109V0h15.68V78.512H46.165L15.9,30.088h-.109V78.512H0V0Z"
                      transform="translate(440.425 188.094)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_9"
                      data-name="Fill 9"
                      d="M16.446,0V63.688H41.337V0H57.675V65.446c0,7.246-3.4,13.066-13.925,13.066H14.034C3.508,78.512,0,72.692,0,65.446V0Z"
                      transform="translate(514.544 188.094)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_10"
                      data-name="Fill 10"
                      d="M0,0H54.827V14.494H35.637V78.512H19.188V14.494H0Z"
                      transform="translate(580.765 188.094)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_11"
                      data-name="Fill 11"
                      d="M0,78.512V0H48.8V14.494H16.449V31.516H36.623V46.34H16.449V64.018h33.99V78.512Z"
                      transform="translate(644.355 188.094)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_12"
                      data-name="Fill 12"
                      d="M0,0V491.742l96.246-38.585V0Z"
                      transform="translate(598.55 383.004)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_13"
                      data-name="Fill 13"
                      d="M0,556.88l48.747,19.542,47.5-19.041V0H0Z"
                      transform="translate(338.559 383.004)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_14"
                      data-name="Fill 14"
                      d="M0,543.853l96.246-38.585V0H0Z"
                      transform="translate(468.555 383.004)"
                      fill="#f7951f"
                    />
                    <path
                      id="Fill_15"
                      data-name="Fill 15"
                      d="M96.246,0H0V453.156l224.991,90.193V438.442L96.246,387.2Z"
                      transform="translate(79.819 383.004)"
                      fill="#fff"
                    />
                    <path id="Fill_16" data-name="Fill 16" d="M0,2.721H614.977V0H0Z" transform="translate(79.818 323.301)" fill="#fff" />
                    <path
                      id="Fill_17"
                      data-name="Fill 17"
                      d="M386.631,1046.5l-1.9-.827L0,891.062V0H8.151V885.542l378.686,152.183L766.463,885.539V0h8.151V891.065l-2.559,1.026L388.821,1045.719Z"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </Link>
        <ul className="mb-0 w-[calc(100%_-_90px)] gap-4 xl:gap-8 hidden list-none items-center justify-start lg:flex">
          {header?.menuHeader?.slice(3, 6).map((l, index) => (
            <NavButton key={parseInt(`1${index}`)} setOpen={setOpen} open={open} l={l} index={parseInt(`1${index}`)} />
          ))}
        </ul>
      </div>
    </div>
  )
}

const NavButton = ({ setOpen, index, l, open }: { setOpen: Dispatch<SetStateAction<number>>; index: number; l: any; open: number }) => {
  return (
    <motion.li onHoverStart={() => setOpen(index)} onHoverEnd={() => setOpen(-1)} className="relative px-2" key={index}>
      <div className="flex flex-wrap items-center">
        <LosseLink className="navbutton relative" to={l?.link?.url || '/'}>
          {l?.link?.title}
          <AnimatePresence>
            {open === index && (
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                exit={{ width: 0 }}
                className="bg-lm-cadmium absolute h-[3px]"
              />
            )}
          </AnimatePresence>
        </LosseLink>
        {l?.submenu && (
          <div onClick={open === index ? () => setOpen(-1) : () => setOpen(index)} className="ml-[15px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={
                open === index
                  ? {
                      transform: 'rotate(180deg)'
                    }
                  : {
                      transform: 'rotate(0deg)'
                    }
              }
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="#F68A2E"
              strokeWidth={1}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="transition-all"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
        )}
      </div>
      {l?.submenu && open === index && (
        <AnimatePresence>
          <HeaderSubMenu fields={l.submenu} />
        </AnimatePresence>
      )}
    </motion.li>
  )
}
