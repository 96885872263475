import { Link, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function Fields({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="Fields">
      <div className="container section">
        <div className="max-w-screen-xl mx-auto w-full grid grid-cols-1 lg:grid-cols-7 gap-10 lg:gap-32">
          <div className="flex col-span-1 lg:col-span-4 justify-center flex-col gap-10">
            <Content className="text-white">{fields.description}</Content>
            <div className="flex lg:flex-row flex-col gap-3">
              {fields.links?.map((link, index) => (
                <Link className={clsx(index === 0 ? 'button-cadmium-lg' : 'button-white-lg')} key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="grid col-span-1 lg:col-span-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 lg:gap-8">
            {fields.items?.map((item, index) => (
              <Link
                to={item?.link?.url || '/'}
                className="w-full p-4 text-center text-white smooth hover:border-lm-cadmium hover:bg-lm-cadmium uppercase font-secondary text-xl lg:text-2xl aspect-square border flex flex-col gap-4 justify-center items-center border-white"
                key={index}
              >
                <LossePlaatjie maxWidth={1000} className="w-16 sm:w-24" src={item?.icon} alt="icon" />
                {item?.link?.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
